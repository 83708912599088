import {
  WEBSOCKET_CONNECTED,
  WEBSOCKET_DISCONNECTED,
  WEBSOCKET_RECONNECTING,
} from "../reducers/websocket.reducer";

export const websocketConnected = () => ({
  type: WEBSOCKET_CONNECTED,
});

export const websocketDisconnected = () => ({
  type: WEBSOCKET_DISCONNECTED,
});

export const websocketReconnecting = () => ({
  type: WEBSOCKET_RECONNECTING,
});
