import {
  BEGIN_LOGIN,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGOUT,
  REFRESH,
  FacilityAccess,
} from "./auth.actions.types";
import axios from "axios";
import { FACILITY_LOADED } from "./facility.actions";

// Login User
export const login =
  (username: string, password: string) => (dispatch: Function) => {
    dispatch({ type: BEGIN_LOGIN });

    axios
      .post(`${process.env.REACT_APP_FACILITY_MANAGER_BASE_URL}/users/login`, {
        email: username,
        password: password,
      })
      .then((res) => {
        // console.log(res.data);
        dispatch({ type: LOGIN_SUCCESS, payload: res.data });
        dispatch({ type: FACILITY_LOADED, payload: res.data });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: LOGIN_FAIL,
        });
      });
  };

export const refresh =
  (facilities: FacilityAccess[]) => (dispatch: Function) => {
    dispatch({ type: REFRESH, payload: facilities });
  };

export const logout = () => (dispatch: Function) => {
  // Ensure localStorage is cleared completely
  localStorage.clear();
  // Dispatch logout action to reset state across all reducers
  dispatch({ type: LOGOUT });
};
