import { useState, useEffect, useCallback, useRef } from 'react';

interface WebSocketHookOptions {
    url: string;
    token: string | null;
    maxReconnectAttempts?: number;
    reconnectInterval?: number;
    keepAliveInterval?: number;
    onMessage?: (data: any) => void;
}

interface WebSocketHookReturn {
    isConnected: boolean;
    isReconnecting: boolean;
    sendMessage: (message: any) => void;
    disconnect: () => void;
}

export const useWebSocket = ({
                                 url,
                                 token,
                                 maxReconnectAttempts = 5,
                                 reconnectInterval = 5000,
                                 keepAliveInterval = 270000, // 4.5 minutes in milliseconds (just under API Gateway's 10 minute limit)
                                 onMessage,
                             }: WebSocketHookOptions): WebSocketHookReturn => {
    const [isConnected, setIsConnected] = useState(false);
    const [isReconnecting, setIsReconnecting] = useState(false);
    const ws = useRef<WebSocket | null>(null);
    const reconnectAttempts = useRef(0);
    const reconnectTimeoutId = useRef<NodeJS.Timeout>();
    const previousToken = useRef<string | null>(null);
    const attemptingConnection = useRef(false);

    const keepAliveIntervalId = useRef<NodeJS.Timeout>();

    // Store the callback in a ref to avoid recreating the WebSocket on every render
    const onMessageCallback = useRef(onMessage);

    useEffect(() => {
        onMessageCallback.current = onMessage;
    }, [onMessage]);

    const sendPing = useCallback(() => {
        if (ws.current?.readyState === WebSocket.OPEN) {
            sendMessage({
                type: 'ping'
            });
        }
    }, []);

    // Clear all existing timers and connection resources
    const clearResources = useCallback(() => {
        if (reconnectTimeoutId.current) {
            clearTimeout(reconnectTimeoutId.current);
            reconnectTimeoutId.current = undefined;
        }
        
        if (keepAliveIntervalId.current) {
            clearInterval(keepAliveIntervalId.current);
            keepAliveIntervalId.current = undefined;
        }
        
        if (ws.current) {
            ws.current.close();
            ws.current = null;
        }
    }, []);
    
    // Log token changes - moved after clearResources definition
    useEffect(() => {        
        // If token is removed (logout), make sure to stop all reconnection attempts
        if (previousToken.current && !token) {
            clearResources();
            setIsConnected(false);
            setIsReconnecting(false);
            reconnectAttempts.current = maxReconnectAttempts; // Prevent auto-reconnect
        }
        
        previousToken.current = token;
    }, [token, clearResources, maxReconnectAttempts]);

    const connect = useCallback(() => {
        if (!token) {
            return; // Don't connect if we don't have a token
        }

        // Prevent concurrent connection attempts
        if (attemptingConnection.current) {
            return;
        }

        attemptingConnection.current = true;
        
        // Clear any existing connection and intervals
        clearResources();

        const fullUrl = `${url}?Auth=${token}`;
        const socket = new WebSocket(fullUrl);

        socket.onopen = () => {
            setIsConnected(true);
            setIsReconnecting(false);
            reconnectAttempts.current = 0;
            attemptingConnection.current = false;
            keepAliveIntervalId.current = setInterval(sendPing, keepAliveInterval);
        };

        socket.onclose = (event) => {
            setIsConnected(false);
            attemptingConnection.current = false;

            // Clear keepalive interval
            if (keepAliveIntervalId.current) {
                clearInterval(keepAliveIntervalId.current);
                keepAliveIntervalId.current = undefined;
            }

            // Only attempt to reconnect if we're not deliberately disconnecting
            // and we have a token
            if (token && (reconnectAttempts.current < maxReconnectAttempts || maxReconnectAttempts <= 0)) {
                setIsReconnecting(true);
                reconnectAttempts.current += 1;
                
                // Don't schedule a reconnect if one is already pending
                if (!reconnectTimeoutId.current) {
                    reconnectTimeoutId.current = setTimeout(() => {
                        reconnectTimeoutId.current = undefined;
                        connect();
                    }, reconnectInterval);
                }
            } else {
                setIsReconnecting(false);
            }
        };

        socket.onerror = (error) => {
            console.error(`WebSocket error:`, error);
        };

        socket.onmessage = (event) => {
            try {
                const data = JSON.parse(event.data);
                onMessageCallback.current?.(data);
            } catch (error) {

            }
        };

        ws.current = socket;
    }, [token, url, maxReconnectAttempts, reconnectInterval, keepAliveInterval, sendPing, clearResources]);

    // Clean up on unmount
    useEffect(() => {
        return () => {
            clearResources();
        };
    }, [clearResources]);

    // Connect when the component mounts and we have a token
    useEffect(() => {
        if (token) {
            connect();
        }

        // Cleanup on unmount or token change
        return () => {
            clearResources();
        };
    }, [connect, token, clearResources]);

    const sendMessage = useCallback((message: any) => {
        if (ws.current?.readyState === WebSocket.OPEN) {
            ws.current.send(JSON.stringify(message));
        }
    }, []);

    const disconnect = useCallback(() => {
        clearResources();
        setIsConnected(false);
        setIsReconnecting(false);
        reconnectAttempts.current = maxReconnectAttempts; // Prevent auto-reconnect
    }, [maxReconnectAttempts, clearResources]);

    return {
        isConnected,
        isReconnecting,
        sendMessage,
        disconnect,
    };
};