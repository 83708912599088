import {
  BAY_STATUS_UPDATED,
  BAYLOADED,
  BAYLOADING,
  BAYSLOADED,
  BAYSLOADING,
  BayState,
  BAYUPDATED,
  BOOKING_UPDATED,
} from "../actions/bays.actions.types";
import {BayDbModel, BayStatusType} from "../../models/bays";

const initialState: BayState = { isLoading: false, bays: [] };

export default (state: BayState = initialState, action: any) => {
  switch (action.type) {
    case BAYSLOADING: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case BAYSLOADED: {
      return {
        ...state,
        // Set the BayStatus for each Bay to BayStatus.Available
        bays: action.payload.map((bay: BayDbModel) => ({
          ...bay,
          bayStatus: BayStatusType.Available,
        })),
        isLoading: false
      };
    }
    case BAYUPDATED: {
      // Incoming bay needs to match the rest of the bays facility_id
      if (state.bays.length > 0) {
        if (state.bays[0].rangeId !== action.payload.facility_id) {
          console.log('BayUpdate: FacilityId mismatch!');
          return state;
        }
      }

      // Bay number is missing from the payload :(
      const bayUpdated = state.bays.find((x) => x.uuid === action.payload.uuid)
      action.payload.bay_number = bayUpdated?.bayNumber;

      return {
        ...state,
        bays: [
          ...state.bays.filter((x) => x.uuid !== action.payload.uuid),
          action.payload,
        ].sort((a: BayDbModel, b: BayDbModel): number => {
          if (a.bayName < b.bayName) return -1;
          if (a.bayName > b.bayName) return 1;
          return 0;
        }),
      };
    }
    case BAYLOADING: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case BAYLOADED: {
      // Bay number is missing from the payload :(
      const bayUpdated = state.bays.find((x) => x.uuid === action.payload.uuid)
      action.payload.bay_number = bayUpdated?.bayNumber;

      return {
        ...state,
        bays: [
          ...state.bays.filter((x) => x.uuid !== action.payload.uuid),
          action.payload,
        ].sort((a: BayDbModel, b: BayDbModel): number => {
          if (a.bayName < b.bayName) return -1;
          if (a.bayName > b.bayName) return 1;
          return 0;
        }),
        isLoading: false,
      };
    }
    case BOOKING_UPDATED: {
      return {
        ...state,
        bays: state.bays.map((bay: BayDbModel) =>
            bay.bayId === action.payload.bayId
                ? {
                  ...bay, // Keep existing bay properties
                  ...action.payload, // Overwrite only the new booking details
                  uuid: bay.uuid ?? action.payload.bayId, // Preserve existing UUID if available
                }
                : bay
        ).sort((a, b) => a.bayName.localeCompare(b.bayName)), // Simplified sorting
      };
    }
    case BAY_STATUS_UPDATED: {
      return {
        ...state,
        bays: state.bays.map((bay: BayDbModel) =>
            bay.bayId === action.payload.bayId
                ? {
                  ...bay,
                  bayStatusType: action.payload.bayStatusType
                }
                : bay
        ).sort((a, b) => a.bayName.localeCompare(b.bayName)), // Simplified sorting
      };
    }
    default:
      return state;
  }
};
