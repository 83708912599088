import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import logo from "../../assets/Flite_Logo_Dark_No_Tag.png";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItemIcon from "@material-ui/core/ListItemIcon";

import { MenuList, Grid, FormControl, Select } from "@material-ui/core";
import { MenuItem } from "@material-ui/core";
import { Facility } from "../../models/facility";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../redux/store";
import { logout } from "../../redux/actions/auth.actions";
import inrangeLogo from "../../assets/inrange_bay_management_logo.svg";
import baysIcon from "../../assets/bays_icon.svg";
import reportsIcon from "../../assets/reports_icon.svg";
import playersIcon from "../../assets/players_icon.svg";
import settingsIcon from "../../assets/settings_icon.svg";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { FacilityAccess } from "../../redux/actions/auth.actions.types";
import {FACILITY_LOADED, selectFacility} from "../../redux/actions/facility.actions";
import { getBays } from "../../redux/actions/bays.actions";
import jwt from "jwt-decode";
import packageJson from "../../../package.json";

interface LayoutProps {
  facility: Facility;
}

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    logo: {
      maxWidth: 160,
      display: "block",
      marginTop: "0px",
      marginLeft: "auto",
      marginRight: "auto",
      marginBottom: "0px",
    },
    inrangelogo: {
      display: "block",
      marginTop: "0px",
      marginLeft: "auto",
      marginRight: "auto",
      marginBottom: "40px",
    },
    drawer: {
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      [theme.breakpoints.up("sm")]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    title: {
      flexGrow: 1,
      textAlign: "center",
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  })
);

export const LayoutMenu: React.FC<LayoutProps> = ({ facility }) => {
  const classes = useStyles();

  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const selectedFacility = useSelector<RootState, Facility>(
    (selector) => selector.facility.selectedFacility
  );
  const facilities = useSelector<RootState, FacilityAccess[]>(
    (selector) => selector.authToken.facilityAccess
        .filter((a:FacilityAccess) => a.name !== null)
        .sort((a:FacilityAccess, b:FacilityAccess) => a.name.localeCompare(b.name))
  );

  const [facilitySelected, setFacilitySelected] = React.useState("");

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const dispatch = useDispatch();
  const isAuthenticated = useSelector<RootState, boolean>(
    (selector) => selector.authToken.isAuthenticated
  );

  const role = useSelector<RootState, string>(
    (selector) => selector.facility.role
  );

  const handleFacilityChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    // console.log(event.target.value);
    if (event.target.value !== "") {
      // console.log("triggering facility change from layout menu");
      const decodedRangesToken: any = jwt(rangesToken);
      var selectedRangeId = event.target.value as string;

      // console.log(selectedRangeId);
      let role = 'USER';
      let name = '';
        decodedRangesToken.ranges.forEach((range: any) => {
          if (range.id === selectedRangeId) {
              role = range.role.toUpperCase();
              name = range.name;
          }
      });

      dispatch(selectFacility(selectedRangeId, role, name));
      dispatch(getBays(selectedRangeId));
    }
    setFacilitySelected(event.target.value as string);
  };
  const rangesToken = useSelector<RootState, string>(
    (selector) => selector.authToken.rangesToken
  );
  useEffect(() => {
    if (facilitySelected !== "") {
      const selectedFacility = localStorage.getItem("selectedfacility");
      // console.log(`selectedFacility: ${selectedFacility}`);
      // const localFacilities = localStorage.getItem("availablefacilities");
      // const role = localStorage.getItem("role");
      // if (!!!selectedFacility || !!!role) {
      //   console.log(`role: ${role}`);
      //   console.log(`selectedFacility: ${selectedFacility}`);
      //   console.log("triggering facility change from layout menu");

      //   const decodedToken: any = jwt(token);
      //   dispatch(
      //     selectFacility(facilitySelected, decodedToken[facilitySelected])
      //   );
      //   dispatch(getBays(facilitySelected));
      // }
    }
  }, [facilitySelected]);

  const handleLogout = () => {
    dispatch(logout());
  };

  useEffect(() => {
    // console.log(facilities);
    // console.log(selectedFacility);
    if (selectedFacility !== undefined && selectedFacility.rangeId !== undefined)
      setFacilitySelected(selectedFacility.rangeId);
  }, [selectedFacility, facilities]);

  const drawer = (
    <MuiThemeProvider theme={theme}>
      <div className={classes.toolbar} />

      <div>
        <img src={inrangeLogo} alt="logo" className={classes.inrangelogo} />
      </div>
      <Divider />
      {isAuthenticated && (
        <React.Fragment>
          {facilities.length > 1 && (
            <FormControl className={classes.formControl}>
              <Select
                labelId="mode-select-label"
                id="mode-select"
                value={facilitySelected}
                onChange={handleFacilityChange}
                className={classes.selectEmpty}
                inputProps={{ "aria-label": "Without label" }}
              >
                {facilities.map((facility) => (
                  <MenuItem key={facility.id} value={facility.id}>
                    {facility.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          <Divider />
          <MenuList>
            <MenuItem component={Link} to="/">
              <ListItemIcon>
                <img src={baysIcon} alt="bays" />
              </ListItemIcon>
              <Typography variant="inherit">Bays</Typography>
              <Grid container justify="flex-end">
                <ListItemIcon>
                  <ChevronRightIcon />
                </ListItemIcon>
              </Grid>
            </MenuItem>
            {/*{isAuthenticated && (role == "MANAGER" || role == "ADMIN") && (*/}
            {/*    <MenuItem component={Link} to="/reports">*/}
            {/*      <ListItemIcon>*/}
            {/*        <img src={reportsIcon} alt="reports" />*/}
            {/*      </ListItemIcon>*/}
            {/*      <Typography variant="inherit">Reports</Typography>*/}
            {/*      <Grid container justify="flex-end">*/}
            {/*        <ListItemIcon>*/}
            {/*          <ChevronRightIcon />*/}
            {/*        </ListItemIcon>*/}
            {/*      </Grid>*/}
            {/*    </MenuItem>*/}
            {/*)}*/}
            {/*{isAuthenticated && role == "DEBUG" && (*/}
            {/*  <MenuItem component={Link} to="/players">*/}
            {/*    <ListItemIcon>*/}
            {/*      <img src={playersIcon} alt="players" />*/}
            {/*    </ListItemIcon>*/}
            {/*    <Typography variant="inherit">Players</Typography>*/}
            {/*    <Grid container justify="flex-end">*/}
            {/*      <ListItemIcon>*/}
            {/*        <ChevronRightIcon />*/}
            {/*      </ListItemIcon>*/}
            {/*    </Grid>*/}
            {/*  </MenuItem>*/}
            {/*)}*/}
          </MenuList>
          <Divider />
        </React.Fragment>
      )}
      {isAuthenticated && (role === "MANAGER" || role === "ADMIN") && (
        <List>
          <MenuItem component={Link} to="/users">
            <ListItemIcon>
              <img src={playersIcon} alt="users" />
            </ListItemIcon>
            <Typography variant="inherit">Users</Typography>
            <Grid container justify="flex-end">
              <ListItemIcon>
                <ChevronRightIcon />
              </ListItemIcon>
            </Grid>
          </MenuItem>
          <MenuItem component={Link} to="/settings">
            <ListItemIcon>
              <img src={settingsIcon} alt="settings" />
            </ListItemIcon>
            <Typography variant="inherit">Settings</Typography>
            <Grid container justify="flex-end">
              <ListItemIcon>
                <ChevronRightIcon />
              </ListItemIcon>
            </Grid>
          </MenuItem>
          <Divider />
        </List>
      )}
      {isAuthenticated && (
        <MenuItem>
          <Grid container justify="center">
            <Button
              variant="contained"
              color="primary"
              onClick={handleLogout}
              fullWidth
            >
              <Typography variant="inherit">Logout</Typography>
            </Button>
          </Grid>
        </MenuItem>
      )}

      {/* <img src={logo} alt="logo" className={classes.logo} /> */}
      <Grid container justify="center">
        <Typography variant="caption" color="primary">
          v{packageJson.version}
        </Typography>
      </Grid>
    </MuiThemeProvider>
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Hidden smUp implementation="css">
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
              onClick={handleDrawerToggle}
            >
              <MenuIcon />
            </IconButton>
            <Hidden xsDown implementation="css">
              <img src={logo} alt="logo" className={classes.logo} />
            </Hidden>
            <div className={classes.title}>
              <Hidden xsDown implementation="css">
                <Typography variant="h3" className={classes.title}>
                  {facility.name}
                </Typography>
              </Hidden>
            </div>
          </Toolbar>
        </AppBar>
      </Hidden>
      <nav className={classes.drawer} aria-label="menu">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
    </div>
  );
};

export default LayoutMenu;
