import React, { useEffect } from "react";
import {
    Dialog,
    DialogContent,
    makeStyles,
    Theme,
    createStyles,
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    Button,
    Snackbar,
    MuiThemeProvider,
    useTheme,
    Paper,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody, Checkbox, Tooltip, DialogTitle, DialogActions,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import {BayDbModel, Pin} from "../../models/bays";
import axios from "axios";
import { Alert, ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import DeleteIcon from "@material-ui/icons/Delete";
import LinkIcon from "@material-ui/icons/Link";
import PersonIcon from '@material-ui/icons/Person';
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { Facility } from "../../models/facility";
import EditIcon from "@material-ui/icons/Edit";
import MessageIcon from "@material-ui/icons/Message";
import DescriptionIcon from "@material-ui/icons/Description";
import { UpdatePin } from "./UpdatePin";


interface AvailablePinProps {
    show: boolean;
    handleClose: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        appBar: {
            position: "relative",
        },
        title: {
            marginLeft: theme.spacing(1),
            flex: 1,
            textAlign: "left",
            font: "normal normal normal 36px/44px Montserrat",
            letterSpacing: "0px",
            color: "#041A27",
            opacity: 1
        },
        noPins: {
            marginLeft: theme.spacing(1),
            flex: 1,
            textAlign: "left",
            font: "normal normal normal 12px/24px Montserrat",
            letterSpacing: "0px",
            color: "#041A27",
            opacity: 1
        },
        root: {
            minWidth: 600,
            "& > *": {
                margin: theme.spacing(1),
            },
        },
        dialog: {
            borderRadius: "15px",
        },
        rootStyle: {
            borderRadius: 12,
        },
        notime: {
            color: "red",
        },
        time: {
            color: "primary",
        },
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
        cellHeading: {
            textAlign: "center",
            font: "normal normal bold 16px/19px Montserrat",
            letterSpacing: "0px",
            color: "#041A27",
            textTransform: "uppercase",
            opacity: 1
        },
        cellEntry: {
            textAlign: "center",
            font: "normal normal normal 16px/19px Montserrat",
            letterSpacing: "0px",
            color: "#041A27",
            opacity: "1",
        },
        cellEntryBold: {
            textAlign: "center",
            font: "normal normal bold 16px/19px Montserrat",
            letterSpacing: "0px",
            color: "#041A27",
            opacity: "1",
        },
        toolbar: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
        },
        toggleButtonGroup: {
            flex: 1,
            display: "flex",
            justifyContent: "start",
        },
    })
);

export const AvailablePins: React.FC<AvailablePinProps> = ({
    show,
    handleClose,
}) => {
    const classes = useStyles();
    const [allPins, setAllPins] = React.useState<Pin[]>([]);
    const [pins, setPins] = React.useState<Pin[]>([]);
    const [loading, setLoading] = React.useState(false);
    const [snackBarOpen, setSnackBarOpen] = React.useState(false);
    const [snackBarMessage, setSnackBarMessage] = React.useState("");

    const [playerPin, setPlayerPin] = React.useState<Pin | undefined>();

    const [showUpdatePin, setShowUpdatePin] = React.useState(false);
    const [updatePin, setUpdatePin] = React.useState<Pin | undefined>();

    const bays = useSelector<RootState, BayDbModel[]>(
        (selector) => selector.bays.bays
    );

    const theme = useTheme();
    const handleSnackBarClose = (
        event?: React.SyntheticEvent,
        reason?: string
    ) => {
        if (reason === "clickaway") {
            return;
        }

        setSnackBarOpen(false);
    };

    const handlePlayerPinClose = (
        event?: React.SyntheticEvent,
    ) => {
        setPlayerPin(undefined)
    };

    const selectedFacility = useSelector<RootState, Facility>(
        (selector) => selector.facility.selectedFacility
    );

    const getAvailablePins = () => {
        if (selectedFacility !== null && selectedFacility.rangeId !== "") {
            // Clear the Pins
            setLoading(true);
            setAllPins([]);
            setPins([]);

            axios
                .get(`${process.env.REACT_APP_BOOKINGS_BASE_URL}/ranges/${selectedFacility.rangeId}/bookings/upcoming?limit=100`)
                .then((res) => {
                    var pinsReturned: Pin[] = res.data;

                    // Pins Bay Number to Bay Name
                    pinsReturned.forEach((pin) => {
                        if (pin.bayNumbers) {
                            pin.bayNumbers.forEach((bayNumber) => {
                                const bay = bays.find((bay) => bay.bayNumber === bayNumber);
                                if (bay) {
                                    pin.bay = bay;
                                }
                            });
                        }
                    });

                    setAllPins(pinsReturned);

                    filterPins(pinsReturned, toggleValue);

                    setLoading(false);
                });
        }
    };

    const refreshPin = (id: string, pin: string) => {
        axios
            .put(
                `${process.env.REACT_APP_BASE_URL}/${selectedFacility.rangeId}/Pins/${pin}/refresh/${id}`,
                {}
            )
            .then((res) => {
                getAvailablePins();
            });
    };

    const deleteBooking = (pin: string, id: string) => {
        // TODO Do we even need Force?

        axios
            .delete(`${process.env.REACT_APP_BOOKINGS_BASE_URL}/ranges/${selectedFacility.rangeId}/bookings/${id}?force=true`)
            .then((res) => {
                getAvailablePins();
            })
            .catch(reason => console.log(reason));
    };

    const handleSubmitLinkPin = () => {
        getAvailablePins()
    }

    const handleUpdatePin = (pin: Pin) => {
        setUpdatePin(pin);
        setShowUpdatePin(true);
    };

    const handleSubmitUpdatePin = () => {
        setUpdatePin(undefined)
        getAvailablePins()
    }

    const handleUpdatePinClose = (
        event?: React.SyntheticEvent,
    ) => {
        setShowUpdatePin(false)
        setUpdatePin(undefined)
    };

    useEffect(() => {
    }, [pins]);

    useEffect(() => {
        if (show) getAvailablePins();
    }, [show]);

    const allowGroupPinOption = useSelector<RootState, boolean>(
        (selector) => (selector.facility.selectedFacility.groupOption.toLowerCase() === 'allow')
    );

    const [toggleValue, setAlignment] = React.useState('Timeslot');

    const handleToggleChange = (
        event: React.MouseEvent<HTMLElement>,
        newToggleValue: string,
    ) => {

        if (newToggleValue) {
            setAlignment(newToggleValue);
            filterPins(allPins, newToggleValue);
        }
    };

    function filterPins(pins: Pin[], toggleValue: string) {
        let filtered;
        if (!toggleValue) {
            toggleValue = 'timeslot';
        }

        switch (toggleValue.toLowerCase()) {
            case 'timeslot':
                filtered = pins.filter((p) => p.timeSlotStart !== undefined)
                break;
            default:
                filtered = pins.filter((p) => p.timeSlotStart === undefined)
                break;
        }
        setPins(filtered);
    }

    function getPinStatus(pin: Pin) {

        if(pin.complete) {
            return "COMPLETE"
        } else if(pin.groupBooking || pin.isStatic) {
            return "-"
        } else if(pin.startTime == null) {
            return "READY"
        } else if(pin.completeTime == null) {
            return "IN PROGRESS"
        }

        return "-"
    }

    function getPinStatusTooltip(pin: Pin) {

        if(pin.groupBooking) {
            return "Multi-Bay PIN"
        } else if(pin.isStatic) {
            return "Static PIN"
        } else if(pin.startTime == null) {
            return "Not started";
        } else if(pin.completeTime == null) {
            return "Started: " + new Date(pin.startTime).toLocaleString()
        } else if(pin.complete) {
            return "Completed: " + new Date(pin.completeTime).toLocaleString()
        }

        return "-"
    }

    function getPlayerTooltip(pin: Pin) {

        if(pin.players) {
            return pin.players.length + " Player(s)";
        }

        return ""
    }


    return (
        <MuiThemeProvider theme={theme}>
            <Dialog
                fullWidth={true}
                maxWidth={'lg'}
                open={show}
                onClose={handleClose}
                classes={{
                    paper: classes.rootStyle,
                }}
                className={classes.dialog}
                aria-labelledby="form-dialog-title"
            >
                <Toolbar className={classes.toolbar}>
                    <Typography variant="h6" className={classes.title}>
                        One-Time Bookings
                    </Typography>
                    <ToggleButtonGroup
                        size='small'
                        color="info"
                        value={toggleValue}
                        exclusive
                        onChange={handleToggleChange}
                        aria-label="Platform"
                        className={classes.toggleButtonGroup}
                    >
                        <ToggleButton value="Timeslot">Timeslot</ToggleButton>
                        <ToggleButton value="No-Timeslot">No Timeslot</ToggleButton>
                    </ToggleButtonGroup>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
                <DialogContent>
                    <React.Fragment>
                        {loading && (
                            <Typography variant="subtitle1" className={classes.noPins}>
                                Loading...
                            </Typography>
                        )}
                        {!loading && pins.length === 0 && (
                            <Typography variant="subtitle1" className={classes.noPins}>
                                No {toggleValue} PINs found
                            </Typography>
                        )}
                        {pins.length > 0 && (
                            <TableContainer>
                                <Table size="small" aria-label="a dense table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className={classes.cellHeading}>PIN</TableCell>
                                            <TableCell className={classes.cellHeading}>Mins</TableCell>
                                            <TableCell className={classes.cellHeading}>Mode</TableCell>
                                            <TableCell className={classes.cellHeading}>Bay</TableCell>
                                            <TableCell className={classes.cellHeading}>Status</TableCell>
                                            {/*<TableCell className={classes.cellHeading}>Start Time</TableCell>*/}
                                            {toggleValue === 'Timeslot' && (
                                                <TableCell className={classes.cellHeading}>Timeslot Start</TableCell>
                                            )}
                                            {toggleValue === 'Timeslot' && (
                                                <TableCell className={classes.cellHeading}>Timeslot End</TableCell>
                                            )}
                                            <TableCell className={classes.cellHeading}>Meta</TableCell>
                                            <TableCell className={classes.cellHeading}>Actions</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {pins.map((row, index) => (
                                            <TableRow
                                                style={index % 2 ? { background: "#F3F3F3" } : { background: "white" }}
                                                key={row.id}>
                                                <TableCell className={classes.cellEntry}>{row.pin}</TableCell>
                                                <TableCell className={classes.cellEntry}>{row.minutes}</TableCell>
                                                <TableCell className={classes.cellEntry}>
                                                    {
                                                        row.playMode === "SP" ? 'Inrange' : (row.playMode === "MP" ? 'Inrange+' : row.playMode)
                                                    }
                                                </TableCell>
                                                <TableCell className={classes.cellEntryBold}>
                                                    <Typography>
                                                    {
                                                        ((!row.complete && row.startTime === undefined && row.timeSlotStart  && row.timeSlotEnd)
                                                            ? <Button
                                                                variant="text"
                                                                size="small"
                                                                onClick={() => {
                                                                    handleUpdatePin(row)
                                                                }}>
                                                                <b>{ row.groupBooking ? 'Multi' : row.bay?.bayName ?? row.bayNumbers }</b>
                                                                <LinkIcon/>
                                                            </Button>
                                                            : (row.bay === undefined ? '-' : (row.bay?.bayName ?? row.bayNumbers)))
                                                    }
                                                    </Typography>
                                                </TableCell>
                                                <TableCell className={classes.cellEntry}>
                                                    <Tooltip title={getPinStatusTooltip(row)} arrow placement="top">
                                                        <Typography>{ getPinStatus(row) }</Typography>
                                                    </Tooltip>
                                                </TableCell>
                                                {/*<TableCell className={classes.cellEntry}>*/}
                                                {/*    {*/}
                                                {/*        row.startTime === null ? '-' : new Date(row.startTime).toLocaleString()*/}
                                                {/*    }*/}
                                                {/*</TableCell>*/}
                                                {toggleValue === 'Timeslot' && (
                                                    <TableCell className={classes.cellEntry}>
                                                        {
                                                            row.timeSlotStart === undefined ? '-' : new Date(row.timeSlotStart).toLocaleString()
                                                        }
                                                    </TableCell>
                                                )}
                                                {toggleValue === 'Timeslot' && (
                                                    <TableCell className={classes.cellEntry}>
                                                        {
                                                            row.timeSlotEnd === undefined ? '-' : new Date(row.timeSlotEnd).toLocaleString()
                                                        }
                                                    </TableCell>
                                                )}
                                                <TableCell className={classes.cellEntryBold}>
                                                    <div style={{
                                                        flexDirection: 'row',
                                                        display: 'flex',
                                                        alignItems: 'flex-start',
                                                        justifyContent: "start"
                                                    }}>
                                                        <Tooltip
                                                            title={row.welcomeMessage === undefined ? '-' : row.welcomeMessage}
                                                            arrow placement="top">
                                                            <Typography hidden={row.welcomeMessage === undefined}>
                                                                <MessageIcon/>
                                                            </Typography>
                                                        </Tooltip>
                                                        <Tooltip title={row.description ?? ''} arrow placement="top">
                                                            <Typography
                                                                hidden={(row.description === undefined || row.description?.length === 0)}>
                                                                <DescriptionIcon/>
                                                            </Typography>
                                                        </Tooltip>
                                                    </div>
                                                </TableCell>
                                                <TableCell className={classes.cellEntry}>
                                                <div style={{flexDirection:'row', display: 'flex', alignItems: 'flex-start', justifyContent: "start"}}>
                                                        {/* PIN Not started yet */}
                                                        <Button
                                                            hidden={row.complete || row.startTime !== undefined}
                                                            variant="text"
                                                            size="small"
                                                            onClick={() => handleUpdatePin(row) }
                                                        >
                                                            <EditIcon />
                                                        </Button>
                                                        {/* Only if not completed */}
                                                        <Button
                                                            hidden={row.complete}
                                                            variant="text"
                                                            size="small"
                                                            onClick={() => deleteBooking(row.pin, row.id)}
                                                        >
                                                            <DeleteIcon />
                                                        </Button>
                                                        <Button
                                                            hidden={row.players === undefined}
                                                            variant="text"
                                                            size="small"
                                                            onClick={() => setPlayerPin(row) }
                                                        >
                                                            <PersonIcon />
                                                        </Button>
                                                    </div>

                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}
                    </React.Fragment>
                    {updatePin == null ? "" : <UpdatePin pin={updatePin!}
                                                         show={showUpdatePin}
                                                         selectedFacility={selectedFacility}
                                                         handleSubmit={handleSubmitUpdatePin}
                                                         handleClose={handleUpdatePinClose}
                    >
                    </UpdatePin>}
                    <PlayerDialog
                        pin={playerPin}
                        open={playerPin !== undefined}
                        onClose={ handlePlayerPinClose }
                    />
                    <Snackbar
                        open={snackBarOpen}
                        autoHideDuration={6000}
                        onClose={handleSnackBarClose}
                    >
                        <Alert onClose={handleSnackBarClose} severity="success">
                            {snackBarMessage}
                        </Alert>
                    </Snackbar>
                </DialogContent>
            </Dialog>
        </MuiThemeProvider>
    );
};


export interface PlayerDialogProps {
    open: boolean;
    pin: Pin | undefined;
    onClose: () => void;
}

export function PlayerDialog(props: PlayerDialogProps) {
    const { onClose, pin, open } = props;

    const handleClose = () => {
        onClose();
    };

    return (
        <Dialog onClose={handleClose}
                open={open}
                fullWidth={true}
                maxWidth={'sm'}>
            <DialogTitle>Player(s) Details</DialogTitle>
            <DialogContent>
                {pin?.players?.map((player, index) => (
                    <div key={index}>
                        Email: {player.email}<br/>
                        Firstname: {player.firstName}<br/>
                        Lastname: {player.lastName}<br/>
                        <br/>
                    </div>
                ))}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>OK</Button>
            </DialogActions>
        </Dialog>
    );
}


