import React, { useState } from "react";
import {
  TextField,
  Dialog,
  DialogContent,
  makeStyles,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  FormControl,
  Button,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Box,
  Slide,
  InputAdornment,
  Fade,
  CircularProgress,
  Snackbar,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import CloseIcon from "@material-ui/icons/Close";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import EmailIcon from "@material-ui/icons/Email";
import PersonIcon from "@material-ui/icons/Person";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import { TransitionProps } from "@material-ui/core/transitions";
import { useSelector, useDispatch } from "react-redux";
import { createUser } from "../../redux/actions/users.actions";
import { UserSignupModel } from "../../models/users";
import { RootState } from "../../redux/store";
import { Facility } from "../../models/facility";
import axios from "axios";

// Slide transition for the dialog
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface UserCreatorProps {
  show: boolean;
  handleClose: () => void;
  refreshUserData?: () => void;
}

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
    backgroundColor: "#e83e8c", // Vibrant pink color from the screenshot
    boxShadow: "none",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    fontWeight: 500,
  },
  closeButton: {
    color: "white",
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.1)",
    },
  },
  dialogPaper: {
    borderRadius: 16,
    overflow: "hidden",
    maxWidth: 600,
    width: "100%",
  },
  content: {
    padding: theme.spacing(4, 3),
  },
  formControl: {
    marginBottom: theme.spacing(3),
    width: "100%",
  },
  inputField: {
    "& .MuiOutlinedInput-root": {
      borderRadius: 30,
      backgroundColor: "#fafafa",
      transition: "all 0.3s ease",
      "&:hover": {
        backgroundColor: "#f5f5f5",
      },
      "&.Mui-focused": {
        backgroundColor: "#ffffff",
        boxShadow: "0 0 0 2px rgba(232, 62, 140, 0.2)",
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#e0e0e0",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#e83e8c",
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color: "#e83e8c",
    },
  },
  selectField: {
    "& .MuiOutlinedInput-root": {
      borderRadius: 30,
      backgroundColor: "#fafafa",
      transition: "all 0.3s ease",
      "&:hover": {
        backgroundColor: "#f5f5f5",
      },
      "&.Mui-focused": {
        backgroundColor: "#ffffff",
        boxShadow: "0 0 0 2px rgba(232, 62, 140, 0.2)",
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#e0e0e0",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#e83e8c",
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color: "#e83e8c",
    },
  },
  createButton: {
    backgroundColor: "#e83e8c",
    color: "white",
    borderRadius: 30,
    padding: theme.spacing(1.2, 4),
    fontSize: "1rem",
    fontWeight: 500,
    boxShadow: "0 4px 12px rgba(232, 62, 140, 0.3)",
    transition: "all 0.3s ease",
    marginTop: theme.spacing(2),
    "&:hover": {
      backgroundColor: "#d81b7a",
      boxShadow: "0 6px 16px rgba(232, 62, 140, 0.4)",
      transform: "translateY(-2px)",
    },
    "&:active": {
      boxShadow: "0 2px 8px rgba(232, 62, 140, 0.4)",
      transform: "translateY(0px)",
    },
  },
  fieldIcon: {
    color: "#9e9e9e",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(2),
  },
  loadingSpinner: {
    color: "#ffffff",
    marginRight: theme.spacing(1),
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  buttonWrapper: {
    position: "relative",
  },
}));

export const UserCreator: React.FC<UserCreatorProps> = ({
  show,
  handleClose,
  refreshUserData,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("User");
  const [showPassword, setShowPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  
  const userRole = useSelector<RootState, string>(
    (selector) => selector.facility.role
  );
  const facility = useSelector<RootState, Facility>(
    (selector) => selector.facility.selectedFacility
  );

  const handleCreate = async () => {
    // Don't proceed if already submitting
    if (isSubmitting) return;
    
    // Validate inputs
    if (!firstName || !lastName || !emailAddress || !password) {
      setError("Please fill in all required fields");
      return;
    }

    setIsSubmitting(true);
    setError(null);
    
    const createdUser: UserSignupModel = {
      email: emailAddress,
      password: password,
      firstName: firstName,
      lastName: lastName,
      rangeId: facility.rangeId,
      role: role
    };
    
    if (userRole === "ADMIN" || userRole === "MANAGER") {
      try {
        dispatch(createUser(createdUser));
        
        try {
          await axios.post(
            `${process.env.REACT_APP_FACILITY_MANAGER_BASE_URL}/users`, 
            createdUser
          );
        } catch (apiError) {
          console.log("Direct API call failed, continuing with process:", apiError);
        }
        
        await new Promise(resolve => setTimeout(resolve, 5000));
        
        if (refreshUserData) {
          refreshUserData();
        }
        
        resetForm();
        handleClose();
      } catch (err) {
        console.error("Error creating user:", err);
        setError("Failed to create user. Please try again.");
      } finally {
        setIsSubmitting(false);
      }
    } else {
      resetForm();
      handleClose();
      setIsSubmitting(false);
    }
  };
  
  const resetForm = () => {
    setFirstName("");
    setLastName("");
    setEmailAddress("");
    setPassword("");
    setRole("User");
    setShowPassword(false);
    setError(null);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Dialog
      open={show}
      onClose={handleClose}
      TransitionComponent={Transition}
      classes={{ paper: classes.dialogPaper }}
      aria-labelledby="user-creation-dialog"
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            className={classes.closeButton}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            User Creation
          </Typography>
        </Toolbar>
      </AppBar>

      <DialogContent className={classes.content}>
        <Fade in={show} timeout={600}>
          <form>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <FormControl className={classes.formControl} variant="outlined">
                  <TextField
                    id="firstName"
                    label="First Name"
                    variant="outlined"
                    fullWidth
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    className={classes.inputField}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PersonIcon className={classes.fieldIcon} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl className={classes.formControl} variant="outlined">
                  <TextField
                    id="lastName"
                    label="Last Name"
                    variant="outlined"
                    fullWidth
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    className={classes.inputField}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PersonIcon className={classes.fieldIcon} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl className={classes.formControl} variant="outlined">
                  <TextField
                    id="emailAddress"
                    label="Email Address"
                    variant="outlined"
                    fullWidth
                    value={emailAddress}
                    onChange={(e) => setEmailAddress(e.target.value)}
                    className={classes.inputField}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <EmailIcon className={classes.fieldIcon} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl className={classes.formControl} variant="outlined">
                  <TextField
                    id="password"
                    label="Password"
                    type={showPassword ? "text" : "password"}
                    variant="outlined"
                    fullWidth
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className={classes.inputField}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <VpnKeyIcon className={classes.fieldIcon} />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={togglePasswordVisibility}
                            edge="end"
                            size="small"
                          >
                            {showPassword ? (
                              <VisibilityOffIcon fontSize="small" />
                            ) : (
                              <VisibilityIcon fontSize="small" />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  variant="outlined"
                  className={`${classes.formControl} ${classes.selectField}`}
                >
                  <InputLabel id="role-label">Role</InputLabel>
                  <Select
                    labelId="role-label"
                    id="role"
                    value={role}
                    onChange={(e) => setRole(e.target.value as string)}
                    label="Role"
                  >
                    <MenuItem value="User">User</MenuItem>
                    <MenuItem value="Manager">Manager</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Box className={classes.buttonContainer}>
              <div className={classes.buttonWrapper}>
                <Button
                  variant="contained"
                  className={classes.createButton}
                  onClick={handleCreate}
                  startIcon={!isSubmitting && <PersonAddIcon />}
                  disableElevation
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <>
                      <CircularProgress size={20} className={classes.loadingSpinner} />
                      CREATING...
                    </>
                  ) : (
                    "CREATE USER"
                  )}
                </Button>
              </div>
            </Box>
          </form>
        </Fade>
      </DialogContent>
      
      {/* Error Notification */}
      <Snackbar 
        open={!!error} 
        autoHideDuration={6000} 
        onClose={() => setError(null)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={() => setError(null)} severity="error">
          {error}
        </Alert>
      </Snackbar>
    </Dialog>
  );
};

export default UserCreator;