import React, { useEffect } from "react";
import {
  Button,
  useTheme,
  ThemeProvider as MuiThemeProvider,
  makeStyles,
  Typography,
  CircularProgress,
  Grid,
  Divider,
  Paper,
  Container,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Fade,
  Chip,
} from "@material-ui/core";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import axios from "axios";
import { useSelector } from "react-redux";
import { UserModel } from "../models/users";
import { Facility } from "../models/facility";
import { RootState } from "../redux/store";
import { UserCreator } from "../components/users/UserCreator";
import FacilityInfo from "../components/FacilityInfo";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  pageHeader: {
    marginBottom: theme.spacing(2),
  },
  headerDivider: {
    margin: theme.spacing(2, 0, 3),
  },
  addButton: {
    margin: theme.spacing(2, 0, 3),
    borderRadius: theme.spacing(5),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(3),
    boxShadow: theme.shadows[2],
    "&:hover": {
      boxShadow: theme.shadows[4],
    },
  },
  table: {
    minWidth: 650,
  },
  tableContainer: {
    marginTop: theme.spacing(3),
    borderRadius: theme.spacing(1),
    boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
    overflow: "hidden",
  },
  tableHeaderCell: {
    fontWeight: 600,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontSize: "1rem",
  },
  tableRow: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    "&:hover": {
      backgroundColor: theme.palette.action.selected,
      transition: "background-color 0.2s ease",
    },
  },
  loadingContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(6),
  },
  contentPaper: {
    padding: theme.spacing(3),
    borderRadius: theme.spacing(1),
    boxShadow: "0 6px 20px rgba(0, 0, 0, 0.08)",
  },
  userCount: {
    display: "inline-block",
    marginLeft: theme.spacing(1),
  },
  titleSection: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(2),
  },
}));

export const Users = () => {
  const theme = useTheme();
  const classes = useStyles();
  const selectedFacility = useSelector<RootState, Facility>(
    (selector) => selector.facility.selectedFacility
  );
  const [userData, setUserData] = React.useState<UserModel[] | null>(null);
  const [loading, setLoading] = React.useState(true); // Set default state to loading
  const [showUserCreator, setShowUserCreator] = React.useState(false);
  const [currentInformationLabel, setCurrentInformationLabel] = React.useState(
    ""
  );

  const getAllUsersForFacility = () => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_FACILITY_MANAGER_BASE_URL}/users/ranges/${selectedFacility.rangeId}`)
      .then((result) => {
        setUserData(result.data as UserModel[]);
        setCurrentInformationLabel("All Users");
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error loading users:", error);
        setLoading(false);
      });
  };

  const handleCloseCreator = () => setShowUserCreator(false);
  const handleShowCreator = () => setShowUserCreator(true);

  useEffect(() => {
    if (selectedFacility && selectedFacility.rangeId) {
      getAllUsersForFacility();
    }
  }, [selectedFacility]);

  const showData = userData && userData.length > 0;

  return (
    <MuiThemeProvider theme={theme}>
      <Container maxWidth="lg" className={classes.root}>
        <Paper className={classes.contentPaper} elevation={3}>
          <Grid container alignItems="center" spacing={2} className={classes.pageHeader}>
            <Grid item xs={12} md={6}>
              <FacilityInfo />
            </Grid>
            <Grid item xs={12} md={6}>
              <Box display="flex" justifyContent="flex-end">
                <Typography variant="h4" color="primary">
                  Users Management
                </Typography>
              </Box>
            </Grid>
          </Grid>
          
          <Divider className={classes.headerDivider} />
          
          <Button
            variant="contained"
            color="primary"
            className={classes.addButton}
            onClick={handleShowCreator}
            startIcon={<PersonAddIcon />}
            size="large"
          >
            Add Player
          </Button>
          
          <UserCreator 
            show={showUserCreator} 
            handleClose={handleCloseCreator} 
            refreshUserData={getAllUsersForFacility} 
          />

          {loading ? (
            <Fade in={loading}>
              <Paper className={classes.loadingContainer} elevation={0}>
                <Typography variant="h6" color="textSecondary" gutterBottom>
                  Loading User Data...
                </Typography>
                <CircularProgress color="primary" size={40} thickness={4} />
              </Paper>
            </Fade>
          ) : showData ? (
            <Fade in={!loading} timeout={500}>
              <div>
                <div className={classes.titleSection}>
                  <Typography variant="h5" color="textPrimary">
                    {currentInformationLabel}
                  </Typography>
                  <Chip 
                    label={`${userData!.length} users`} 
                    color="primary" 
                    variant="outlined" 
                    size="small" 
                    className={classes.userCount}
                  />
                </div>
                
                <TableContainer component={Paper} className={classes.tableContainer}>
                  <Table className={classes.table} aria-label="users table">
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.tableHeaderCell}>First Name</TableCell>
                        <TableCell className={classes.tableHeaderCell}>Last Name</TableCell>
                        <TableCell className={classes.tableHeaderCell}>Email Address</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {userData!.map((user) => (
                        <TableRow key={user.id} className={classes.tableRow}>
                          <TableCell>{user.firstName}</TableCell>
                          <TableCell>{user.lastName}</TableCell>
                          <TableCell>{user.email}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </Fade>
          ) : (
            <Fade in={!loading} timeout={500}>
              <Paper className={classes.loadingContainer} elevation={1}>
                <Typography variant="h6" color="textSecondary" gutterBottom>
                  No users found
                </Typography>
                <Typography variant="body2" color="textSecondary" align="center">
                  There are no users associated with this facility yet.
                </Typography>
              </Paper>
            </Fade>
          )}
        </Paper>
      </Container>
    </MuiThemeProvider>
  );
};

export default Users;