import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import "../styles/Bays.css";
import "../styles/global.css";
import {
  Grid,
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
  makeStyles,
  Theme,
  createStyles,
  InputAdornment,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
} from "@material-ui/core";
import jwt from "jwt-decode";
import logo from "../assets/inrange_bay_management_logo.svg";
import { login } from "../redux/actions/auth.actions";
import { AuthState, FacilityAccess } from "../redux/actions/auth.actions.types";
import { RootState } from "../redux/store";
import { selectFacility } from "../redux/actions/facility.actions";
import { getBays } from "../redux/actions/bays.actions";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexWrap: "wrap",
      width: 400,
      margin: `${theme.spacing(0)} auto`,
    },
    loginBtn: {
      marginTop: theme.spacing(2),
      flexGrow: 1,
    },
    logo: {
      display: "block",
      marginLeft: "auto",
      marginRight: "auto",
      marginBottom: "20px",
    },
    header: {
      textAlign: "center",
      background: "#212121",
      color: "#fff",
    },
    card: {
      marginTop: theme.spacing(10),
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
  })
);

export const Login: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  let history = useHistory();

  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [error, setError] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [helperText, setHelperText] = useState("");
  const [facility, setFacility] = React.useState("");
  const [loggingIn, setLoggingIn] = React.useState(false);

  const isAuthenticated = useSelector<RootState, boolean>(
    (selector) => selector.authToken.isAuthenticated
  );

  const rangesToken = useSelector<RootState, string>(
    (selector) => selector.authToken.rangesToken
  );

  const facilities = useSelector<RootState, FacilityAccess[]>(
    (selector) => selector.authToken.facilityAccess
  );

  const authError = useSelector<RootState, boolean>(
    (selector) => selector.authToken.authError
  );

  const handleLogin = () => {
    setLoggingIn(true);
    dispatch(login(username, password));
  };

  const handleKeyPress = (e: any) => {
    if (e.keyCode === 13 || e.which === 13) {
      isButtonDisabled || handleLogin();
    }
  };

  const handleFacilityChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setFacility(event.target.value as string);
  };

  useEffect(() => {
    if (username.trim() && password.trim()) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [username, password]);

  useEffect(() => {
    if (facility !== "") {
      const decodedRangesToken: any = jwt(rangesToken);
      let role = 'USER';
      let name = '';
      decodedRangesToken.ranges.forEach((range: any) => {
        if (range.id === facility) {
          role = range.role.toUpperCase();
          name = range.name;
        }
      });

      dispatch(selectFacility(facility, role, name));
      dispatch(getBays(facility));
      history.push("/");
    }
  }, [facility]);

  useEffect(() => {
    if (isAuthenticated) {
      setLoggingIn(false);
      setError(false);
      setHelperText("Login Successfully");
      if (facilities.length === 1) {
        setFacility(facilities[0].id);
      }
    } else if (authError) {
      setLoggingIn(false);
      setError(true);
      setHelperText("Incorrect username or password");
    }
  }, [isAuthenticated, authError]);

  return (
    <form className={classes.container} noValidate autoComplete="off">
      <Card
        className={classes.card}
        style={{
          width: "400px",
        }}
      >
        <CardContent
          style={{
            marginTop: "20px",
            marginLeft: "20px",
            marginRight: "20px",
          }}
        >
          <div>
            <img src={logo} alt="logo" className={classes.logo} />
          </div>
          {!isAuthenticated && (
            <Grid container spacing={8} alignItems="flex-end">
              <Grid item md={true} sm={true} xs={true}>
                <div className="TextField-with-border-radius">
                  <TextField
                    error={error}
                    helperText={helperText}
                    onChange={(e) => setUsername(e.target.value)}
                    onKeyPress={(e) => handleKeyPress(e)}
                    id="username"
                    label="Email Address"
                    type="email"
                    fullWidth
                    autoFocus
                    variant="outlined"
                    required
                  />
                </div>
              </Grid>
            </Grid>
          )}
          {!isAuthenticated && (
            <Grid container spacing={8} alignItems="flex-end">
              <Grid item md={true} sm={true} xs={true}>
                <div className="TextField-with-border-radius">
                  <TextField
                    error={error}
                    helperText={helperText}
                    onChange={(e) => setPassword(e.target.value)}
                    onKeyPress={(e) => handleKeyPress(e)}
                    id="password"
                    label="Password"
                    type="password"
                    fullWidth
                    variant="outlined"
                    required
                  />
                </div>
              </Grid>
            </Grid>
          )}
          {isAuthenticated && facilities.length > 1 && (
            <div className="TextField-with-border-radius">
              <FormControl
                variant="outlined"
                className={classes.formControl}
                fullWidth
              >
                <InputLabel id="mode-select-label" color="primary">
                  Select Facility
                </InputLabel>
                <Select
                  labelId="mode-select-label"
                  id="mode-select"
                  value={facility}
                  onChange={handleFacilityChange}
                  label="Select Facility"
                >
                  {facilities.map((facility) => (
                    <MenuItem key={facility.id} value={facility.id}>
                      {facility.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          )}
        </CardContent>

        <CardActions>
          {!isAuthenticated && (
            <Button
              variant="contained"
              size="large"
              color="primary"
              className={classes.loginBtn}
              onClick={() => handleLogin()}
              disabled={isButtonDisabled}
              style={{
                marginLeft: "20px",
                marginRight: "20px",
                marginBottom: "20px",
              }}
            >
              {loggingIn == false && <React.Fragment>Login</React.Fragment>}
              {loggingIn == true && <CircularProgress color="secondary" />}
            </Button>
          )}
        </CardActions>
      </Card>
    </form>
  );
};

export default Login;
