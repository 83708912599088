// import { LOGIN_FAIL, LOGIN_SUCCESS, LOGOUT } from "./auth.actions.types";
import axios from "axios";
import { Facility } from "../../models/facility";

export const FACILITY_CHANGE = "FACILITY_CHANGE";
export const FACILITY_LOADED = "FACILITY_LOADED";
export const FACILITY_UPDATING = "FACILITY_UPDATING";
export const FACILITY_UPDATED = "FACILITY_UPDATED";

export interface FacilityState {
  selectedFacility: Facility;
  role: string;
  facilities: Facility[];
}

// get selected facility config
export const selectFacility =
  (facilityId: string, role: string, name: string) => (dispatch: Function) => {

    if (
      facilityId === undefined ||
      facilityId === null ||
      facilityId === "" ||
      facilityId === "undefined"
    ) {
      return;
    }

    // We'll set localStorage after config is fetched successfully to ensure we only
    // store valid facilities

    axios
      .get(
        `${process.env.REACT_APP_BOOKINGS_BASE_URL}/config/ranges/${facilityId}`
      )
      .then((res) => {
        // Only store in localStorage after successful API call
        localStorage.setItem("selectedfacility", facilityId);
        localStorage.setItem("role", role?.toUpperCase());

        dispatch({
          type: FACILITY_CHANGE,
          payload: res.data,
          role: role,
          name: name,
        });
        // dispatch({ type: FACILITY_UPDATED, payload: res.data, role: role });
      })
      .catch((err) => {
        console.error(`Error fetching facility config:`, err);
      });
  };

export const updateFacility =
  (facilityId: string, updatedFacility: Facility) => (dispatch: Function) => {
    dispatch({ type: FACILITY_UPDATING });

    if (
      facilityId === undefined ||
      facilityId === null ||
      facilityId === "" ||
      facilityId === "undefined"
    ) {
      return;
    }

    axios
      .put(
        `${process.env.REACT_APP_BOOKINGS_BASE_URL}/config/ranges/${facilityId}`,
        {
          rangeId: updatedFacility.rangeId,
          behavior: updatedFacility.behavior,
          leadTimeMinutes: updatedFacility.leadTimeMinutes,
          groupOption: updatedFacility.groupOption,
        }
      )
      .then((result) => {
        dispatch({ type: FACILITY_UPDATED, payload: result.data });
      })
      .catch((err) => {
        console.error(`Error updating facility:`, err);
      });
  };
