import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Bays from "./pages/Bays";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Login from "./pages/Login";
import { LayoutMenu } from "./components/layout/LayoutMenu";
import { Facility } from "./models/facility";
import axios from "axios";
import ProtectedRoute, {
  ProtectedRouteProps,
} from "./components/layout/PrivateRoute";
import { Settings } from "./pages/Settings";
import { RootState } from "./redux/store";
import { useSelector, useDispatch } from "react-redux";
import { AuthState, FacilityAccess } from "./redux/actions/auth.actions.types";
import { selectFacility } from "./redux/actions/facility.actions";
import jwt from "jwt-decode";
import {getBays, bookingUpdated, bayStatusUpdated} from "./redux/actions/bays.actions";
import Users from "./pages/Users";
import { refresh, logout } from "./redux/actions/auth.actions";
import {useWebSocket} from "./components/websocket/websocket";
import { websocketConnected, websocketDisconnected, websocketReconnecting } from "./redux/actions/websocket.actions";

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    logo: {
      maxWidth: 160,
    },
    drawer: {
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      [theme.breakpoints.up("sm")]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    title: {
      flexGrow: 1,
      textAlign: "center",
    },
  })
);

const App: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const UNAUTHORIZED = 401;

  const facility = useSelector<RootState, Facility>(
    (selector) => selector.facility.selectedFacility
  );

  const defaultProtectedRouteProps: ProtectedRouteProps = {
    authenticationPath: "/login",
    isAllowed: true,
    restrictedPath: "",
  };

  axios.interceptors.request.use(
    function (config) {
      // console.log(token);
        const token = localStorage.getItem("inrangeauthtoken") || "";
      if (token != null && token !== "") {
        // console.log(token);
        var isExpired = false;
        var decodedToken: any = jwt(token);
        var dateNow = new Date();

        var d = new Date(0); // The 0 there is the key, which sets the date to the epoch
        d.setUTCSeconds(decodedToken.exp);

        if (d < dateNow) isExpired = true;

        // console.log(isExpired);
        if (isExpired) {
          //force logout
          console.log('FORCE LOGOUT');
          dispatch(logout());
        } else {
            // config.headers.Authorization = `Bearer ${token}`;
            config.headers['x-access-token'] = token;
        }
      }

      return config;
    },
    function (err) {
      return Promise.reject(err);
    }
  );

    axios.interceptors.response.use(
        response => response,
        error => {
            const {status} = error.response;
            if (status === UNAUTHORIZED) {
                console.log('UNAUTHORIZED LOGOUT');
                dispatch(logout());
            }
            return Promise.reject(error);
        }
    );


    const selectedFacility = useSelector<RootState, Facility>(
        (selector) => selector.facility.selectedFacility
    );

    // Get this token from your login process
    const [authToken, setAuthToken] = useState<string | null>(null);
    const authState = useSelector<RootState, AuthState>((selector) => selector.authToken);

    // Track login events by watching auth state changes
    useEffect(() => {        
        if (authState.isAuthenticated) {
            const token = localStorage.getItem("inrangeauthtoken");            
            if (token) {
                setAuthToken(token);
            }
        } else {
            // User has logged out, clear the authToken to stop websocket connections
            setAuthToken(null);
        }
    }, [authState.isAuthenticated]);

    useEffect(() => {
        const token = localStorage.getItem("inrangeauthtoken");
        
        if (token && !authToken) {
            setAuthToken(token);
        }
    }, [authToken]);

    const { isConnected, isReconnecting, sendMessage } = useWebSocket({
        url: process.env.REACT_APP_WSS_URL!,
        token: authToken,
        onMessage: (data) => {
            // Handle incoming messages here
            if (data.event === 'booking-updated') {
                dispatch(bookingUpdated(data.payload, facility));
            } else if (data.event === 'bay-status-updated') {
                dispatch(bayStatusUpdated(data.payload, facility));
            }
        },
    });

    useEffect(() => {        
        // Update Redux store with websocket status
        if (isConnected) {
            dispatch(websocketConnected());
        } else if (isReconnecting) {
            dispatch(websocketReconnecting());
        } else {
            dispatch(websocketDisconnected());
        }
    }, [isConnected, isReconnecting, dispatch]);

    useEffect(() => {
        if (isConnected && selectedFacility && selectedFacility.rangeId) {
            sendMessage({
                action: 'register',
                rangeId: selectedFacility.rangeId
            });
        } else if (!isConnected && selectedFacility?.rangeId) {
            console.log(`Not connected yet but facility selected: ${selectedFacility.rangeId}`);
        } else if (isConnected && (!selectedFacility || !selectedFacility.rangeId)) {
            console.log(`Connected but no facility selected or invalid facility`);
        }
    }, [isConnected, selectedFacility, sendMessage]);

  useEffect(() => {
    const selectedFacility = localStorage.getItem("selectedfacility");    
    const localFacilities = localStorage.getItem("availablefacilities");
    const token = localStorage.getItem("inrangeauthtoken");
    
    const rangesToken = localStorage.getItem("inrangerangestoken");
    
    if (!!token) {
      const decodedToken: any = jwt(token);

        // console.log(token);
        var isExpired = false;
        var dateNow = new Date();
        var d = new Date(0); // The 0 there is the key, which sets the date to the epoch
        d.setUTCSeconds(decodedToken.exp);

        if (d < dateNow) isExpired = true;

        if (isExpired) {
            //force logout
            dispatch(logout());
        } else {
            setAuthToken(token);

            // console.log(decodedToken);
            var facilities: FacilityAccess[];
            if (localFacilities !== null) {
                facilities = JSON.parse(localFacilities);
                dispatch(refresh(facilities));
            }
            if (!!selectedFacility && rangesToken) {
                let role = 'USER';
                let name = '';
                const decodedRangesToken: any = jwt(rangesToken);
                decodedRangesToken.ranges.forEach((range: any) => {
                    if (range.id === selectedFacility) {
                        role = range.role.toUpperCase();
                        name = range.name;
                    }
                });

                dispatch(selectFacility(selectedFacility, role, name));
                dispatch(getBays(selectedFacility));
            }
        }
    }
  }, []);

  return (
    <div className={classes.root} style={{ background: "#f5f5f5" }}>
      <CssBaseline />
      <BrowserRouter>
        <LayoutMenu facility={facility} />
        <main className={classes.content}>
          {/* <div className={classes.toolbar} /> */}
          <Switch>
            <Route
              exact={true}
              path="/login"
              render={(props) => (
                <React.Fragment>
                  <Login />
                </React.Fragment>
              )}
            />
            <ProtectedRoute
              {...defaultProtectedRouteProps}
              exact={true}
              path="/"
              component={Bays}
            />
            {/*<ProtectedRoute*/}
            {/*  {...defaultProtectedRouteProps}*/}
            {/*  path="/reports"*/}
            {/*  component={Reports}*/}
            {/*/>*/}
            {/*<ProtectedRoute*/}
            {/*  {...defaultProtectedRouteProps}*/}
            {/*  path="/players"*/}
            {/*  render={(props) => <Players />}*/}
            {/*/>*/}
            <ProtectedRoute
              {...defaultProtectedRouteProps}
              path="/settings"
              render={(props) => <Settings facility={facility} />}
            />
            <ProtectedRoute
              {...defaultProtectedRouteProps}
              path="/users"
              component={Users}
            />
          </Switch>
        </main>
      </BrowserRouter>
    </div>
  );
};

export default App;
