import React, { useEffect } from "react";
import {
    Dialog,
    DialogContent,
    makeStyles,
    Theme,
    createStyles,
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    Button,
    Snackbar,
    MuiThemeProvider,
    useTheme,
    Paper,
    FormControlLabel,
    Checkbox,
    Select,
    TextField,
    FormLabel,
    RadioGroup,
    Radio,
    MenuItem,
    FormControl,
    Grid, Divider, AccordionSummary, Accordion, AccordionDetails, InputLabel
} from "@material-ui/core";
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import {
    DateTimePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import Tabs from "@material-ui/core/Tabs";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import Tab from "@material-ui/core/Tab";
import CloseIcon from "@material-ui/icons/Close";
import { Alert } from "@material-ui/lab";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { CreatePin } from "./CreatePin";
import { pinErrorHandled } from '../../redux/actions/pins.actions';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {BayDbModel} from "../../models/bays";
// Add these imports
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";

// Global configuration for maximum players allowed
export const MAX_PLAYERS_ALLOWED = 1;

interface CreatePinOptionsProps {
    role: string;
    show: boolean;
    allowGroupPinOption: boolean;
    handleClose: () => void;
}

function TabPanel(props: { [x: string]: any; children: any; value: any; index: any; }) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            <Box p={3}>{children}</Box>
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
};

function a11yProps(index: number) {
    return {
        id: `scrollable-auto-tab-${index}`,
        "aria-controls": `scrollable-auto-tabpanel-${index}`
    };
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        appBar: {
            position: "relative",
        },
        title: {
            marginLeft: theme.spacing(1),
            flex: 1,
            textAlign: "left",
            font: "normal normal normal 36px/44px Montserrat",
            letterSpacing: "0px",
            color: "#041A27",
            opacity: "1",
        },
        root: {
            padding: theme.spacing(2),
            background: "white",
            "& > *": {
                margin: theme.spacing(1),
            },
        },
        notime: {
            color: "red",
        },
        time: {
            color: "primary",
        },
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
        playerList: {
            width: '100%',
            backgroundColor: theme.palette.background.paper,
        },
        playerItem: {
            borderBottom: `1px solid ${theme.palette.divider}`,
        },
        addPlayerButton: {
            marginTop: theme.spacing(2),
        },
    })
);

export const CreatePinOptions: React.FC<CreatePinOptionsProps> = ({
    role,
    allowGroupPinOption,
    show,
    handleClose
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();

    useEffect(() => {
        if (show) {
            // Start Time Slot to now
            setTimeSlotStart(new Date());
            // End Time Slot data 1 hour into the future
            const timeSlotEndDate = new Date();
            timeSlotEndDate.setHours(timeSlotEndDate.getHours() + 1);
            setTimeSlotEnd(timeSlotEndDate);
        }
    }, [show]);

    const bays = useSelector<RootState, BayDbModel[]>(
        (selector) => selector.bays.bays
    );

    const [usePinTimeSlot, setUsePinTimeSlot] = React.useState(false);
    const [createPin, setCreatePin] = React.useState(false);

    const [snackBarOpen, setSnackBarOpen] = React.useState(false);
    const [snackBarMessage, setSnackBarMessage] = React.useState("");

    const [minutes, setMinutes] = React.useState("60");
    const [isStatic, setIsStatic] = React.useState(false);
    const [isGroupPin, setIsGroupPin] = React.useState(false);
    const [mode, setMode] = React.useState("MP");
    const [newPin, setNewPin] = React.useState("");
    const [description, setDescription] = React.useState("");
    const [welcomeMessage, setWelcomeMessage] = React.useState<string | undefined>(undefined);

    const [timeSlotStart, setTimeSlotStart] = React.useState<Date | null>(new Date());
    const [timeSlotEnd, setTimeSlotEnd] = React.useState<Date | null>(new Date());
    const [errorPinTimeSlotMessage, setErrorPinTimeSlotMessage] = React.useState('');

    const [baysSelected, setBaysSelected] = React.useState<string[]>([]);

    // Add these state variables
    const [playersList, setPlayersList] = React.useState<Array<{
        firstName: string;
        lastName: string;
        email: string;
    }>>([]);

    const [currentPlayer, setCurrentPlayer] = React.useState<{
        firstName?: string;
        lastName?: string;
        email?: string;
        isEmailValid: boolean;
        isFirstNameValid: boolean;
        isLastNameValid: boolean;
    }>({
        isEmailValid: false,
        isFirstNameValid: false,
        isLastNameValid: false
    });

    const handleCurrentPlayerChange = (field: string, value: string) => {
        const updatedPlayer = { ...currentPlayer };

        if (field === 'email') {
            updatedPlayer.email = value;
            updatedPlayer.isEmailValid = validateEmail(value);
        } else if (field === 'firstName') {
            updatedPlayer.firstName = value;
            updatedPlayer.isFirstNameValid = value.trim().length > 0;
        } else if (field === 'lastName') {
            updatedPlayer.lastName = value;
            updatedPlayer.isLastNameValid = value.trim().length > 0;
        }

        setCurrentPlayer(updatedPlayer);
    };

    const handleAddPlayer = () => {
        if (isPlayerFormValid()) {
            setPlayersList([
                ...playersList,
                {
                    firstName: currentPlayer.firstName!,
                    lastName: currentPlayer.lastName!,
                    email: currentPlayer.email!
                }
            ]);

            // Reset current player form
            setCurrentPlayer({
                firstName: undefined,
                lastName: undefined,
                email: undefined,
                isEmailValid: false,
                isFirstNameValid: false,
                isLastNameValid: false
            });
        }
    };

    const handleRemovePlayer = (index: number) => {
        const updatedPlayersList = [...playersList];
        updatedPlayersList.splice(index, 1);
        setPlayersList(updatedPlayersList);
    };

    const isPlayerFormValid = () => {
        return currentPlayer.isEmailValid &&
            currentPlayer.isFirstNameValid &&
            currentPlayer.isLastNameValid;
    };

    const canAddMorePlayers = () => {
        return !isGroupPin && playersList.length < MAX_PLAYERS_ALLOWED;
    };

    const handleShowCreatePin = () => {
        setUsePinTimeSlot(false);
        setCreatePin(true);
    }

    const handleShowCreatePinForTimeSlot = () => {
        setErrorPinTimeSlotMessage('');
        if (timeSlotStart === null || timeSlotEnd === null) {
            setErrorPinTimeSlotMessage('Invalid start and end time slot');
            return;
        }

        const diff = timeSlotEnd.getTime() - timeSlotStart.getTime()
        if (diff <= 0) {
            setErrorPinTimeSlotMessage('Invalid start and end time slot');
            return
        }

        const minutes = Math.floor((diff / 1000) / 60);

        setUsePinTimeSlot(true);
        setMinutes(minutes.toString())
        setCreatePin(true)
    };

    const handleCloseCreatePinOptions = () => {
        setTimeSlotStart(new Date());
        const timeSlotEndDate = new Date();
        timeSlotEndDate.setHours(timeSlotEndDate.getHours() + 1);
        setTimeSlotEnd(timeSlotEndDate);

        setUsePinTimeSlot(false);
        setCreatePin(false)
        setIsStatic(false);
        setIsGroupPin(false);
        setMode("MP");
        setMinutes("60");
        setNewPin("");
        setDescription("");
        setWelcomeMessage(undefined);
        setBaysSelected([]);

        setExpandedAccordion(0);

        setPlayersList([]);
        setCurrentPlayer({
            firstName: undefined,
            lastName: undefined,
            email: undefined,
            isEmailValid: false,
            isFirstNameValid: false,
            isLastNameValid: false
        });

        handleClose()
        dispatch(pinErrorHandled());
    };

    const handleRetryCreatePinOptions = () => {
        setTimeSlotStart(new Date());
        const timeSlotEndDate = new Date();
        timeSlotEndDate.setHours(timeSlotEndDate.getHours() + 1);
        setTimeSlotEnd(timeSlotEndDate);

        setUsePinTimeSlot(false);
        setCreatePin(false)
        setIsStatic(false);
        setIsGroupPin(false);
        setMode("MP");
        setMinutes("60");
        setNewPin("");
        setDescription("");
        setWelcomeMessage(undefined);
        setBaysSelected([]);

        setPlayersList([]);
        setCurrentPlayer({
            firstName: undefined,
            lastName: undefined,
            email: undefined,
            isEmailValid: false,
            isFirstNameValid: false,
            isLastNameValid: false
        });
    };

    const handleIsStaticChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsStatic(event.target.checked);
        setNewPin("");
        setDescription("");
        setWelcomeMessage(undefined);
        setBaysSelected([]);

        setPlayersList([]);
        setCurrentPlayer({
            firstName: undefined,
            lastName: undefined,
            email: undefined,
            isEmailValid: false,
            isFirstNameValid: false,
            isLastNameValid: false
        });

    };

    const handleBaySelectedChange = (
        event: React.ChangeEvent<{ value: unknown }>
    ) => {
        if (isGroupPin) {
            const bays = (event.target.value as string[]);
            bays.sort((a, b) => a.localeCompare(b));
            setBaysSelected(bays);
        } else {
            setBaysSelected([event.target.value as string]);
        }
    };

    const handleIsGroupPinChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsGroupPin(event.target.checked);
        setNewPin("");
        setBaysSelected([]);
    };
    const handleMinutesChange = (
        event: React.ChangeEvent<{ value: unknown }>
    ) => {
        setMinutes(event.target.value as string);
    };
    const handleCustomMinutesChange = (
        event: React.ChangeEvent<{ value: unknown }>
    ) => {

        let minuteValue = parseInt(event.target.value as string)

        if (event.target.value === "" || !isNaN(minuteValue)) {
            minuteValue = minuteValue < 1 ? 1 : minuteValue > 480 ? 480 : minuteValue;
            setMinutes((isNaN(minuteValue) ? "" : minuteValue).toString())
        }
    };
    const handleModeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setMode(event.target.value as string);
    };
    const handleNewPinChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setNewPin(event.target.value as string);
    };
    const handleDescriptionChange = (
        event: React.ChangeEvent<{ value: unknown }>
    ) => {
        setDescription(event.target.value as string);
    };
    const handleWelcomeMessageChange = (
        event: React.ChangeEvent<{ value: unknown }>
    ) => {
        const newMessage = (event.target.value as string).trim() === '' ? undefined : event.target.value as string;
        setWelcomeMessage(newMessage);
    };

    const validateEmail = (email: string) => {
        const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        return regex.test(email);
    };

    const [value, setValue] = React.useState(0);
    function handleChange(event: any, newValue: React.SetStateAction<number>) {
        setValue(newValue);

        setExpandedAccordion(0);
        setTimeSlotStart(new Date());
        const now = new Date();
        now.setHours(now.getHours() + 1);
        setTimeSlotEnd(now);
        setIsStatic(false);
        setIsGroupPin(false);
    }

    const handleSnackBarClose = (
        event?: React.SyntheticEvent,
        reason?: string
    ) => {
        if (reason === "clickaway") {
            return;
        }

        setSnackBarOpen(false);
    };

    const [expandedAccordion, setExpandedAccordion] = React.useState(0);

    const handleAccordionChange = (panel: number) => (event: any, isExpanded: boolean) => {
        setExpandedAccordion(isExpanded ? panel : -1);
    };


    return (
        <MuiThemeProvider theme={theme}>
            <Dialog
                open={show}
                onClose={handleCloseCreatePinOptions}
                aria-labelledby="form-dialog-title"
            >
                <Toolbar>
                    <Typography variant="h6" className={classes.title}>
                        Create Booking
                    </Typography>
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={handleCloseCreatePinOptions}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
                <DialogContent>
                    <React.Fragment>

                        {!createPin && (
                            <AppBar position="static" color="default">
                                <Tabs
                                    value={value}
                                    onChange={handleChange}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    variant="fullWidth"
                                    scrollButtons="auto"
                                    aria-label="scrollable auto tabs example"
                                >
                                    <Tab label="TIME SLOT" {...a11yProps(0)} />
                                    <Tab label="DURATION" {...a11yProps(1)} />
                                </Tabs>
                            </AppBar>
                        )}
                        {!createPin && (
                            <TabPanel value={value} index={0} style={{ minWidth: "100%" }}>
                                <Paper elevation={0}>
                                    <Accordion expanded={expandedAccordion === 0}
                                        onChange={handleAccordionChange(0)}
                                    >
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Divider />
                                            <Typography>
                                                Details
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Grid container alignItems="center" spacing={2}>
                                                <Grid item xs={12}>
                                                    {errorPinTimeSlotMessage && (
                                                        <Typography color="error">
                                                            {errorPinTimeSlotMessage}
                                                        </Typography>
                                                    )}
                                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                        <FormLabel component="legend">Start Slot</FormLabel>
                                                        <DateTimePicker value={timeSlotStart} onChange={setTimeSlotStart} style={{ minWidth: '100%' }} />
                                                    </MuiPickersUtilsProvider>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                        <FormLabel component="legend">End Slot</FormLabel>
                                                        <DateTimePicker value={timeSlotEnd} onChange={setTimeSlotEnd} style={{ minWidth: '100%' }} />
                                                    </MuiPickersUtilsProvider>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <div className="TextField-with-border-radius">
                                                        <FormControl component="fieldset">
                                                            <FormLabel component="legend">Mode</FormLabel>
                                                            <RadioGroup row aria-label="mode" name="mode" value={mode}
                                                                onChange={handleModeChange}>
                                                                <FormControlLabel value={"MP"} control={<Radio />} label="Inrange+" />
                                                                <FormControlLabel value={"SP"} control={<Radio />} label="Inrange" />
                                                            </RadioGroup>
                                                        </FormControl>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    {allowGroupPinOption && (
                                                        <div className="TextField-with-border-radius">
                                                            <FormControl component="fieldset">
                                                                <FormLabel component="legend">Multi-Bay (Multi-Bay PINs cannot be extended)</FormLabel>
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            checked={isGroupPin}
                                                                            onChange={handleIsGroupPinChange}
                                                                            name="checkIsGroupPin"
                                                                            color="primary"
                                                                        />
                                                                    }
                                                                    label="Enable Multi-Bay Pin"
                                                                    labelPlacement="end"
                                                                />
                                                            </FormControl>
                                                        </div>
                                                    )}
                                                </Grid>
                                                {isStatic && (
                                                    <Grid xs={12} item>
                                                        <FormControl variant="outlined" className={classes.formControl}
                                                            fullWidth={true}>
                                                            <TextField
                                                                label="PIN (Optional)"
                                                                variant="outlined"
                                                                color="primary"
                                                                value={newPin}
                                                                inputProps={{
                                                                    errorText: "Please enter a valid and unused PIN",
                                                                    maxLength: 4,
                                                                }}
                                                                error={
                                                                    (newPin != "" &&
                                                                        (isNaN(Number(newPin)) || newPin.length != 4)) ||
                                                                    (newPin.length === 4)
                                                                }
                                                                helperText={
                                                                    newPin != ""
                                                                        ? newPin.length === 4
                                                                            ? !isNaN(Number(newPin))
                                                                                ? ""
                                                                                : "Please enter a valid PIN"
                                                                            : "Please enter a full 4 digit PIN"
                                                                        : ""
                                                                }
                                                                onChange={handleNewPinChange}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                )}
                                                <Grid xs={12} item>
                                                    <FormControl variant="outlined" className={classes.formControl}
                                                        fullWidth={true}>
                                                        <TextField
                                                            label="Description (Optional)"
                                                            variant="outlined"
                                                            color="primary"
                                                            value={description}
                                                            inputProps={{ maxLength: 24 }}
                                                            onChange={handleDescriptionChange}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion
                                        expanded={expandedAccordion === 1}
                                        onChange={handleAccordionChange(1)}
                                    >
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Divider />
                                            <Typography>
                                                Details (Optional)
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Grid container alignItems="center" spacing={2}>
                                                <Grid item xs={12}>
                                                    {errorPinTimeSlotMessage && (
                                                        <Typography color="error">
                                                            {errorPinTimeSlotMessage}
                                                        </Typography>
                                                    )}
                                                </Grid>
                                                <Grid xs={12} item>
                                                    <FormControl variant="outlined" className={classes.formControl} fullWidth={true}>
                                                        <FormLabel component="legend">Link Booking to a Bay</FormLabel>
                                                        <Select
                                                            id="bays"
                                                            label="Bay"
                                                            color="primary"
                                                            value={baysSelected}
                                                            multiple={isGroupPin}
                                                            onChange={handleBaySelectedChange}
                                                            variant="outlined"
                                                            renderValue={(selected) => (
                                                                <div>
                                                                    { baysSelected.map((value, index) => (
                                                                        <span key={value}>{ value }{ index !== baysSelected.length - 1 && ',' } </span>
                                                                    ))}
                                                                </div>
                                                            )}
                                                        >
                                                            {bays.map((bay, index) => (
                                                                <MenuItem key={bay.uuid} value={bay.bayName}>{bay.bayName} ({bay.bayNumber})</MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                    <FormControl variant="outlined"
                                                                 hidden={baysSelected.length <= 0}
                                                                 className={classes.formControl} fullWidth={true}>
                                                        <FormLabel component="legend">Custom Welcome Message</FormLabel>
                                                        <TextField
                                                            label="Optional"
                                                            variant="outlined"
                                                            color="primary"
                                                            value={welcomeMessage || ""}
                                                            inputProps={{ maxLength: 50 }}
                                                            onChange={handleWelcomeMessageChange}
                                                        />
                                                    </FormControl>
                                                    <Grid item xs={12}>
                                                        {playersList.length > 0 && (
                                                            <div>
                                                                <Typography variant="subtitle1">
                                                                    Players ({playersList.length}/{MAX_PLAYERS_ALLOWED})
                                                                </Typography>
                                                                <List className={classes.playerList}>
                                                                    {playersList.map((player, index) => (
                                                                        <ListItem key={index} className={classes.playerItem}>
                                                                            <ListItemText
                                                                                primary={`${player.firstName} ${player.lastName}`}
                                                                                secondary={player.email}
                                                                            />
                                                                            <ListItemSecondaryAction>
                                                                                <IconButton edge="end" aria-label="delete" onClick={() => handleRemovePlayer(index)}>
                                                                                    <DeleteIcon />
                                                                                </IconButton>
                                                                            </ListItemSecondaryAction>
                                                                        </ListItem>
                                                                    ))}
                                                                </List>
                                                            </div>
                                                        )}
                                                        {canAddMorePlayers() && (
                                                            <>
                                                                <Typography variant="subtitle1" style={{ marginTop: '16px' }}>
                                                                    {playersList.length > 0 ? 'Add Another Player' : 'Add Player'}
                                                                </Typography>

                                                                <FormControl variant="outlined" className={classes.formControl} fullWidth={true}>
                                                                    <TextField
                                                                        label="Email"
                                                                        variant="outlined"
                                                                        color="primary"
                                                                        value={currentPlayer.email || ""}
                                                                        onChange={(e) => handleCurrentPlayerChange('email', e.target.value)}
                                                                        error={currentPlayer.email !== undefined && !currentPlayer.isEmailValid}
                                                                        helperText={currentPlayer.email !== undefined && !currentPlayer.isEmailValid ? 'Email must be valid!' : ''}
                                                                    />
                                                                </FormControl>

                                                                <FormControl variant="outlined" className={classes.formControl} fullWidth={true}>
                                                                    <TextField
                                                                        label="First name"
                                                                        variant="outlined"
                                                                        color="primary"
                                                                        value={currentPlayer.firstName || ""}
                                                                        onChange={(e) => handleCurrentPlayerChange('firstName', e.target.value)}
                                                                        error={currentPlayer.firstName !== undefined && !currentPlayer.isFirstNameValid}
                                                                        helperText={currentPlayer.firstName !== undefined && !currentPlayer.isFirstNameValid ? 'First name must be valid!' : ''}
                                                                    />
                                                                </FormControl>

                                                                <FormControl variant="outlined" className={classes.formControl} fullWidth={true}>
                                                                    <TextField
                                                                        label="Last name"
                                                                        variant="outlined"
                                                                        color="primary"
                                                                        value={currentPlayer.lastName || ""}
                                                                        onChange={(e) => handleCurrentPlayerChange('lastName', e.target.value)}
                                                                        error={currentPlayer.lastName !== undefined && !currentPlayer.isLastNameValid}
                                                                        helperText={currentPlayer.lastName !== undefined && !currentPlayer.isLastNameValid ? 'Last name must be valid!' : ''}
                                                                    />
                                                                </FormControl>

                                                                <Button
                                                                    variant="contained"
                                                                    startIcon={<AddIcon />}
                                                                    color="primary"
                                                                    onClick={handleAddPlayer}
                                                                    disabled={!isPlayerFormValid()}
                                                                    className={classes.addPlayerButton}
                                                                >
                                                                    Add Player
                                                                </Button>
                                                            </>
                                                        )}
                                                    </Grid>
                                                    {/*<FormControl component="fieldset" hidden={isGroupPin} fullWidth={true}>*/}
                                                    {/*    <FormControlLabel*/}
                                                    {/*        control={*/}
                                                    {/*            <Checkbox*/}
                                                    {/*                name="checkIsLinkPin"*/}
                                                    {/*                color="primary"*/}
                                                    {/*                disabled={isGroupPin}*/}
                                                    {/*                onChange={handleIsLinkPinChange}*/}
                                                    {/*                value={isLinkPin}*/}
                                                    {/*            />*/}
                                                    {/*        }*/}
                                                    {/*        label="Add player details"*/}
                                                    {/*        labelPlacement="end"*/}
                                                    {/*    />*/}
                                                    {/*</FormControl>*/}
                                                    {/*<FormControl variant="outlined" className={classes.formControl}*/}
                                                    {/*    fullWidth={true}>*/}
                                                    {/*    <TextField*/}
                                                    {/*        label="Email"*/}
                                                    {/*        variant="outlined"*/}
                                                    {/*        color="primary"*/}
                                                    {/*        hidden={!isLinkPin}*/}
                                                    {/*        value={playerEmail || ""}*/}
                                                    {/*        inputProps={{ maxLength: 80 }}*/}
                                                    {/*        onChange={handlePlayerEmailChange}*/}
                                                    {/*        error={!isPlayerEmailValid}*/}
                                                    {/*        helperText={!isPlayerEmailValid ? 'Email must be valid!' : ''}*/}
                                                    {/*    />*/}

                                                    {/*</FormControl>*/}
                                                    {/*<FormControl variant="outlined" className={classes.formControl}*/}
                                                    {/*    fullWidth={true}>*/}
                                                    {/*    <TextField*/}
                                                    {/*        label="Firstname"*/}
                                                    {/*        variant="outlined"*/}
                                                    {/*        color="primary"*/}
                                                    {/*        hidden={!isLinkPin}*/}
                                                    {/*        value={playerFirstname || ""}*/}
                                                    {/*        inputProps={{ maxLength: 24 }}*/}
                                                    {/*        onChange={handlePlayerFirstnameChange}*/}
                                                    {/*        error={!isPlayerFirstNameValid}*/}
                                                    {/*        helperText={!isPlayerFirstNameValid ? 'First name must be valid!' : ''}*/}
                                                    {/*    />*/}
                                                    {/*</FormControl>*/}
                                                    {/*<FormControl variant="outlined" className={classes.formControl}*/}
                                                    {/*    fullWidth={true}>*/}
                                                    {/*    <TextField*/}
                                                    {/*        label="Lastname"*/}
                                                    {/*        variant="outlined"*/}
                                                    {/*        color="primary"*/}
                                                    {/*        hidden={!isLinkPin}*/}
                                                    {/*        value={playerLastname || ""}*/}
                                                    {/*        inputProps={{ maxLength: 24 }}*/}
                                                    {/*        onChange={handlePlayerLastnameChange}*/}
                                                    {/*        error={!isPlayerLastNameValid}*/}
                                                    {/*        helperText={!isPlayerLastNameValid ? 'Last name must be valid!' : ''}*/}
                                                    {/*    />*/}
                                                    {/*</FormControl>*/}
                                                </Grid>
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Grid xs={12} item>
                                        <FormControl variant="outlined" className={classes.formControl}
                                            fullWidth={true}>
                                            <Button variant="contained"
                                                color="primary"
                                                style={{ minWidth: '400px' }}
                                                fullWidth={true}
                                                onClick={handleShowCreatePinForTimeSlot}
                                            >
                                                Create Booking
                                            </Button>
                                        </FormControl>
                                    </Grid>
                                </Paper>
                            </TabPanel>
                        )}
                        {!createPin && (
                            <TabPanel value={value} index={1} style={{ minWidth: "100%" }}>
                                <Paper elevation={0}>
                                    <Grid container alignItems="center">
                                        <Grid item xs={12}>
                                            {(role === "MANAGER" || role === "ADMIN") && (
                                                <FormControl component="fieldset">
                                                    <FormLabel component="legend">Static (Static PINs cannot be extended)</FormLabel>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={isStatic}
                                                                onChange={handleIsStaticChange}
                                                                name="checkIsStatic"
                                                                color="primary"
                                                            />
                                                        }
                                                        label="Static"
                                                        labelPlacement="end"
                                                    />
                                                </FormControl>
                                            )}
                                        </Grid>
                                        <Grid item xs={12}>
                                            <div className="TextField-with-border-radius" style={{ minWidth: "100%" }}>
                                                <FormControl component="fieldset">
                                                    <FormLabel component="legend">Duration</FormLabel>
                                                    <RadioGroup row aria-label="duration" name="duration" value={minutes}
                                                                onChange={handleMinutesChange}>
                                                        <FormControlLabel value={"30"} control={<Radio />}
                                                                          label="30 minutes+" className="col-md-5" style={{ paddingRight: "0px" }} />
                                                        <FormControlLabel value={"60"} control={<Radio />}
                                                                          label="1 hour" className="col-md-5" style={{ paddingRight: "0px" }} />
                                                        <FormControlLabel value={"90"} control={<Radio />}
                                                                          label="1 hour 30 minutes" className="col-md-5" style={{ paddingRight: "0px" }} />
                                                        <FormControlLabel value={"120"} control={<Radio />}
                                                                          label="2 hours" className="col-md-5" style={{ paddingRight: "0px" }} />
                                                        <FormControlLabel value={"150"} control={<Radio />}
                                                                          label="2 hours 30 minutes" className="col-md-5" style={{ paddingRight: "0px" }} />
                                                        <FormControlLabel value={"180"} control={<Radio />}
                                                                          label="3 hours" className="col-md-5" style={{ paddingRight: "0px" }} />
                                                        <FormControlLabel value={"210"} control={<Radio />}
                                                                          label="3 hours 30 minutes" className="col-md-5" style={{ paddingRight: "0px" }} />
                                                        <FormControlLabel value={"240"} control={<Radio />}
                                                                          label="4 hours" className="col-md-5" style={{ paddingRight: "0px" }} />
                                                    </RadioGroup>
                                                </FormControl>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <div className="TextField-with-border-radius">
                                                <FormControl component="fieldset" style={{ marginBottom: "10px" }}>
                                                    <FormLabel component="legend">Custom Duration</FormLabel>
                                                    <TextField
                                                        label="Duration (Minutes)"
                                                        variant="standard"
                                                        color="primary"
                                                        value={minutes}
                                                        inputProps={{ maxLength: 24 }}
                                                        onChange={handleCustomMinutesChange}
                                                    />
                                                </FormControl>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <div className="TextField-with-border-radius">
                                                <FormControl component="fieldset">
                                                    <FormLabel component="legend">Mode</FormLabel>
                                                    <RadioGroup row aria-label="mode" name="mode" value={mode}
                                                                onChange={handleModeChange}>
                                                        <FormControlLabel value={"MP"} control={<Radio />} label="Inrange+" className="col-md-5" />
                                                        <FormControlLabel value={"SP"} control={<Radio />} label="Inrange" className="col-md-5" />
                                                    </RadioGroup>
                                                </FormControl>
                                            </div>
                                        </Grid>
                                        <Grid xs={12} item>
                                            {isStatic && (
                                                <FormControl variant="outlined" className={classes.formControl}
                                                             fullWidth={true}>
                                                    <TextField
                                                        label="PIN (Optional)"
                                                        variant="outlined"
                                                        color="primary"
                                                        value={newPin}
                                                        inputProps={{
                                                            errorText: "Please enter a valid and unused PIN",
                                                            maxLength: 4,
                                                        }}
                                                        error={
                                                            (newPin != "" &&
                                                                (isNaN(Number(newPin)) || newPin.length != 4)) ||
                                                            (newPin.length === 4)
                                                        }
                                                        helperText={
                                                            newPin != ""
                                                                ? newPin.length === 4
                                                                    ? !isNaN(Number(newPin))
                                                                        ? ""
                                                                        : "Please enter a valid PIN"
                                                                    : "Please enter a full 4 digit PIN"
                                                                : ""
                                                        }
                                                        onChange={handleNewPinChange}
                                                    />
                                                </FormControl>
                                            )}
                                        </Grid>
                                        <Grid xs={12} item>
                                            {(
                                                <FormControl variant="outlined" className={classes.formControl}
                                                             fullWidth={true}>
                                                    <TextField
                                                        label="Description (Optional)"
                                                        variant="outlined"
                                                        color="primary"
                                                        value={description}
                                                        inputProps={{ maxLength: 24 }}
                                                        onChange={handleDescriptionChange}
                                                    />
                                                </FormControl>
                                            )}
                                        </Grid>
                                        <Grid xs={12} item>
                                            <FormControl variant="outlined" className={classes.formControl}
                                                         fullWidth={true}>
                                                <Button variant="contained"
                                                        color="primary"
                                                        style={{ minWidth: '400px' }}
                                                        fullWidth={true}
                                                        onClick={handleShowCreatePin}
                                                >
                                                    Create Booking
                                                </Button>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </TabPanel>
                        )}
                        <CreatePin
                            show={createPin}
                            usePinTimeSlot={usePinTimeSlot}
                            handleClose={handleCloseCreatePinOptions}
                            handleRetry={handleRetryCreatePinOptions}
                            minutes={isNaN(parseInt(minutes)) ? "60" : minutes}
                            mode={mode}
                            isStatic={isStatic}
                            groupBooking={isGroupPin}
                            newPin={newPin}
                            description={description}
                            welcomeMessage={welcomeMessage}
                            timeSlotStart={timeSlotStart}
                            timeSlotEnd={timeSlotEnd}
                            bays={ baysSelected.map((bayName) => bays.find((b) => b.bayName === bayName)).filter(Boolean) as BayDbModel[] }
                            playersList={playersList}
                        />

                    </React.Fragment>
                    <Snackbar
                        open={snackBarOpen}
                        autoHideDuration={6000}
                        onClose={handleSnackBarClose}
                    >
                        <Alert onClose={handleSnackBarClose} severity="success">
                            {snackBarMessage}
                        </Alert>
                    </Snackbar>
                </DialogContent>
            </Dialog>
        </MuiThemeProvider>
    );
};
