import React, {useEffect, useState} from "react";
import {
    Dialog,
    DialogContent,
    makeStyles,
    Theme,
    createStyles,
    Toolbar,
    IconButton,
    Typography,
    Snackbar,
    MuiThemeProvider,
    useTheme,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    Divider,
    Grid,
    Button,
    FormLabel,
    FormControlLabel, Checkbox, RadioGroup, Radio, TextField, List, ListItem, ListItemText, ListItemSecondaryAction
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import { Alert } from "@material-ui/lab";
import { useDispatch, useSelector } from "react-redux";
import { pinErrorHandled } from '../../redux/actions/pins.actions';
import {BayDbModel, isGroupBooking, Pin} from "../../models/bays";
import { RootState } from "../../redux/store";
import {
    MuiPickersUtilsProvider
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { DateTimePicker } from "@material-ui/pickers";
import axios from "axios";
import {Facility} from "../../models/facility";
import { MAX_PLAYERS_ALLOWED } from "./CreatePinOptions";

interface LinkPinProps {
    pin: Pin;
    show: boolean;
    selectedFacility: Facility;
    handleSubmit: () => void;
    handleClose: () => void;
}

interface PlayerFormData {
    firstName: string | undefined;
    lastName: string | undefined;
    email: string | undefined;
    isEmailValid: boolean;
    isFirstNameValid: boolean;
    isLastNameValid: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        appBar: {
            position: "relative",
        },
        title: {
            marginLeft: theme.spacing(1),
            flex: 1,
            textAlign: "left",
            font: "normal normal normal 36px/44px Montserrat",
            letterSpacing: "0px",
            color: "#041A27",
            opacity: "1",
        },
        root: {
            padding: theme.spacing(2),
            background: "white",
            "& > *": {
                margin: theme.spacing(1),
            },
        },
        notime: {
            color: "red",
        },
        time: {
            color: "primary",
        },
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
        cellHeading: {
            textAlign: "center",
            font: "normal normal bold 16px/19px Montserrat",
            letterSpacing: "0px",
            color: "#041A27",
            textTransform: "uppercase",
            opacity: 1
        },
        cellEntry: {
            textAlign: "center",
            font: "normal normal normal 16px/19px Montserrat",
            letterSpacing: "0px",
            color: "#041A27",
            opacity: "1",
        },
        cellEntryBold: {
            textAlign: "center",
            font: "normal normal bold 16px/19px Montserrat",
            letterSpacing: "0px",
            color: "#041A27",
            opacity: "1",
        },
        noPins: {
            marginLeft: theme.spacing(1),
            flex: 1,
            textAlign: "left",
            font: "normal normal normal 12px/24px Montserrat",
            letterSpacing: "0px",
            color: "#041A27",
            opacity: 1
        },
        playerList: {
            width: "100%",
            marginTop: theme.spacing(2),
            backgroundColor: theme.palette.background.paper,
        },
        addPlayerButton: {
            marginTop: theme.spacing(2),
        },
        playerItem: {
            border: "1px solid #e0e0e0",
            marginBottom: theme.spacing(1),
            borderRadius: theme.shape.borderRadius,
        },
    })
);

export const UpdatePin: React.FC<LinkPinProps> = ({
                                                      pin,
                                                      show,
                                                      selectedFacility,
                                                      handleSubmit,
                                                      handleClose
                                                  }) => {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();

    const bays = useSelector<RootState, BayDbModel[]>(
        (selector) => selector.bays.bays
    );

    const [snackBarOpen, setSnackBarOpen] = React.useState(false);
    const [snackBarMessage, setSnackBarMessage] = React.useState("");

    const [errorPinTimeSlotMessage, setErrorPinTimeSlotMessage] = React.useState('');
    const [timeSlotStart, setTimeSlotStart] = React.useState<Date | null>(
        pin.timeSlotStart ? new Date(pin.timeSlotStart) : null
    );
    const [timeSlotEnd, setTimeSlotEnd] = React.useState<Date | null>(
        pin.timeSlotEnd ? new Date(pin.timeSlotEnd) : null
    );
    const [mode, setMode] = React.useState(pin.playMode);

    const [baysSelected, setBaysSelected] = React.useState<string[]>([]);
    const [welcomeMessage, setWelcomeMessage] = React.useState<string | undefined>(pin.welcomeMessage);

    // Multiple players state
    const [playersList, setPlayersList] = useState<PlayerFormData[]>([]);
    const [showPlayerDetails, setShowPlayerDetails] = React.useState(!pin.groupBooking);

    // Current player being edited (for the form)
    const [currentPlayer, setCurrentPlayer] = useState<PlayerFormData>({
        firstName: undefined,
        lastName: undefined,
        email: undefined,
        isEmailValid: false,
        isFirstNameValid: false,
        isLastNameValid: false
    });

    useEffect(() => {
        if (show) {
            const bayNames = [];
            if (pin.groupBooking) {
                const bayNumbers = pin.bayNumbers ?? [];
                bayNumbers.forEach((bayNumber) => {
                    const bay = bays.find((b) => b.bayNumber === bayNumber);
                    if (bay) {
                        bayNames.push(bay.bayName);
                    }
                });
            } else if (pin.bay?.uuid) {
                const bay = bays.find((b) => b.uuid === pin.bay?.uuid)
                if (bay) {
                    bayNames.push(bay.bayName);
                }
            } else if (pin.bayNumbers) {
                if (pin.bayNumbers.length === 1) {
                    const bayNumber = pin.bayNumbers[0];
                    const bay = bays.find((bay) => bay.bayNumber === bayNumber);
                    if (bay) {
                        bayNames.push(bay.bayName);
                    }
                }
            }
            setBaysSelected(bayNames);
        }
    }, []);

    // Initialize players list from pin data
    useEffect(() => {
        if (pin.players && pin.players.length > 0) {
            const initialPlayers = pin.players.map(player => ({
                firstName: player.firstName || undefined,
                lastName: player.lastName || undefined,
                email: player.email || undefined,
                isEmailValid: validateEmail(player.email || ""),
                isFirstNameValid: Boolean(player.firstName && player.firstName.trim().length > 0),
                isLastNameValid: Boolean(player.lastName && player.lastName.trim().length > 0)
            }));
            setPlayersList(initialPlayers);
        } else if (pin.playerName || pin.playerEmail) {
            // Handle legacy single player data
            const initialPlayer = {
                firstName: pin.playerName || undefined,
                lastName: pin.playerLastName || undefined,
                email: pin.playerEmail || undefined,
                isEmailValid: validateEmail(pin.playerEmail || ""),
                isFirstNameValid: Boolean(pin.playerName && pin.playerName.trim().length > 0),
                isLastNameValid: Boolean(pin.playerLastName && pin.playerLastName.trim().length > 0)
            };
            setPlayersList([initialPlayer]);
        }
    }, [pin]);

    const handleWelcomeMessageChange = (
        event: React.ChangeEvent<{ value: unknown }>
    ) => {
        const newMessage = (event.target.value as string).trim() === '' ? undefined : event.target.value as string;
        setWelcomeMessage(newMessage);
    };

    const handleBaySelectedChange = (
        event: React.ChangeEvent<{ value: unknown }>
    ) => {
        if (pin.groupBooking) {
            const bays = (event.target.value as string[]);
            bays.sort((a, b) => a.localeCompare(b));
            setBaysSelected(bays);
        } else {
            setBaysSelected([event.target.value as string]);
        }
    };

    const handleCloseUpdatePins = () => {
        handleClose()
        dispatch(pinErrorHandled());
    };

    const handleSubmitUpdatePin = () => {
        updatePin(pin.id)
    }

    const handleSnackBarClose = (
        event?: React.SyntheticEvent,
        reason?: string
    ) => {
        if (reason === "clickaway") {
            return;
        }
        setSnackBarOpen(false);
    };

    const validateEmail = (email: string) => {
        if (!email) return false;
        const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        return regex.test(email);
    };

    const validateField = (value: string | undefined) => {
        return Boolean(value && value.trim().length > 0);
    };

    const handleCurrentPlayerChange = (field: keyof PlayerFormData, value: string) => {
        const updatedPlayer = { ...currentPlayer };

        if (field === 'email') {
            updatedPlayer.email = value.trim() === '' ? undefined : value;
            updatedPlayer.isEmailValid = validateEmail(value);
        } else if (field === 'firstName') {
            updatedPlayer.firstName = value.trim() === '' ? undefined : value;
            updatedPlayer.isFirstNameValid = validateField(value);
        } else if (field === 'lastName') {
            updatedPlayer.lastName = value.trim() === '' ? undefined : value;
            updatedPlayer.isLastNameValid = validateField(value);
        }

        setCurrentPlayer(updatedPlayer);
    };

    const handleAddPlayer = () => {
        // Check if current player form is valid before adding
        if (currentPlayer.isEmailValid && currentPlayer.isFirstNameValid && currentPlayer.isLastNameValid) {
            // Check if we've reached the maximum number of players allowed
            if (playersList.length < MAX_PLAYERS_ALLOWED) {
                setPlayersList([...playersList, {...currentPlayer}]);

                // Reset current player form
                setCurrentPlayer({
                    firstName: undefined,
                    lastName: undefined,
                    email: undefined,
                    isEmailValid: false,
                    isFirstNameValid: false,
                    isLastNameValid: false
                });
            } else {
                // Show a message that maximum players reached
                setSnackBarMessage(`Maximum of ${MAX_PLAYERS_ALLOWED} player${MAX_PLAYERS_ALLOWED > 1 ? 's' : ''} allowed`);
                setSnackBarOpen(true);
            }
        }
    };

    const handleRemovePlayer = (index: number) => {
        const updatedPlayers = [...playersList];
        updatedPlayers.splice(index, 1);
        setPlayersList(updatedPlayers);
    };

    const handleModeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setMode(event.target.value as string);
    };

    const isPlayerFormValid = () => {
        return currentPlayer.isEmailValid && currentPlayer.isFirstNameValid && currentPlayer.isLastNameValid;
    };

    const isFormValid = () => {
        // Check if time slots are valid
        if (timeSlotStart === null || timeSlotEnd === null) {
            return false;
        }

        return true;
    };

    // Function to check if more players can be added
    const canAddMorePlayers = () => {
        return playersList.length < MAX_PLAYERS_ALLOWED;
    };

    const updatePin = (bookingId: string) => {
        setErrorPinTimeSlotMessage('');
        if (timeSlotStart === null || timeSlotEnd === null) {
            setErrorPinTimeSlotMessage('Invalid start and end time slot');
            return;
        }

        const diff = timeSlotEnd.getTime() - timeSlotStart.getTime();
        if (diff <= 0) {
            setErrorPinTimeSlotMessage('Invalid start and end time slot');
            return;
        }

        // Set seconds and milliseconds to zero
        if (timeSlotStart) {
            timeSlotStart.setSeconds(0, 0);
        }
        if (timeSlotEnd) {
            timeSlotEnd.setSeconds(0, 0);
        }

        const minutes = Math.floor((diff / 1000) / 60);

        // Convert to bay numbers from bay names
        const bayDbModels = baysSelected.map((bayName) => bays.find((b) => b.bayName === bayName)).filter(Boolean) as BayDbModel[];
        const bayNumbers = bayDbModels.map((bay) => bay.bayNumber);

        // Prepare players data for submission (only applies if not a group booking!)
        const playersData = pin.groupBooking ? [] : playersList.map(player => ({
            firstName: player.firstName,
            lastName: player.lastName,
            email: player.email
        }));

        const data: {
            playMode: string;
            minutes: number;
            timeSlotStart: Date;
            timeSlotEnd: Date;
            welcomeMessage: string | undefined;
            bayNumbers: number[] | undefined;
            players: Array<{
                firstName: string | undefined;
                lastName: string | undefined;
                email: string | undefined;
            }> | undefined;
        } = {
            minutes: minutes,
            playMode: mode,
            timeSlotStart: timeSlotStart,
            timeSlotEnd: timeSlotEnd,
            welcomeMessage: welcomeMessage ? (welcomeMessage.trim().length === 0 ? undefined : welcomeMessage) : undefined,
            bayNumbers: bayNumbers.length === 0 ? undefined : bayNumbers,
            players: playersData,
        };

        axios
            .put(
                `${process.env.REACT_APP_BOOKINGS_BASE_URL}/ranges/${selectedFacility.rangeId}/bookings/${bookingId}`,
                data
            )
            .then((res) => {
                handleSubmit();
            })
            .catch(reason => console.log(reason));
    };

    return (
        <MuiThemeProvider theme={theme}>
            <Dialog
                fullWidth={true}
                maxWidth={'md'}
                open={show}
                onClose={handleCloseUpdatePins}
                aria-labelledby="form-dialog-title">

                <Toolbar>
                    <Typography variant="h6" className={classes.title}>
                        Update Booking: {pin?.pin}
                    </Typography>
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={handleCloseUpdatePins}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
                <DialogContent>
                    <React.Fragment>
                        <Divider />
                        <div className="TextField-with-border-radius">
                            <Grid container alignItems="center" spacing={2}>
                                <Grid item xs={12}>
                                    <FormControl variant="outlined" className={classes.formControl} fullWidth={true}>
                                        <FormLabel component="legend">Link Booking to a Bay</FormLabel>
                                        <Select
                                            id="bays"
                                            label="Bay"
                                            color="primary"
                                            value={baysSelected}
                                            multiple={pin.groupBooking}
                                            onChange={handleBaySelectedChange}
                                            variant="outlined"
                                            renderValue={(selected) => (
                                                <div>
                                                    { baysSelected.map((value, index) => (
                                                        <span key={value}>{ value }{ index !== baysSelected.length - 1 && ',' } </span>
                                                    ))}
                                                </div>
                                            )}
                                        >
                                            {bays.map((bay, index) => (
                                                <MenuItem key={bay.uuid} value={bay.bayName}>{bay.bayName} ({bay.bayNumber})</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <div className="TextField-with-border-radius">
                                        <FormControl component="fieldset">
                                            <FormLabel component="legend">Mode</FormLabel>
                                            <RadioGroup row aria-label="mode" name="mode" value={mode}
                                                        onChange={handleModeChange}>
                                                <FormControlLabel value={"MP"} control={<Radio />} label="Inrange+" />
                                                <FormControlLabel value={"SP"} control={<Radio />} label="Inrange" />
                                            </RadioGroup>
                                        </FormControl>
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    {errorPinTimeSlotMessage && (
                                        <Typography color="error">
                                            {errorPinTimeSlotMessage}
                                        </Typography>
                                    )}
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <FormLabel component="legend">Start Slot</FormLabel>
                                        <DateTimePicker value={timeSlotStart} onChange={setTimeSlotStart}
                                                        style={{minWidth: '100%'}}/>
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item xs={12}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <FormLabel component="legend">End Slot</FormLabel>
                                        <DateTimePicker value={timeSlotEnd} onChange={setTimeSlotEnd}
                                                        style={{minWidth: '100%'}}/>
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl variant="outlined"
                                                 hidden={baysSelected.length <= 0}
                                                 className={classes.formControl} fullWidth={true}>
                                        <FormLabel component="legend">Custom Welcome Message</FormLabel>
                                        <TextField
                                            label="Optional"
                                            variant="outlined"
                                            color="primary"
                                            value={welcomeMessage || ""}
                                            inputProps={{ maxLength: 50 }}
                                            onChange={handleWelcomeMessageChange}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>

                                    {showPlayerDetails && (
                                        <>
                                            {/* Current players list */}
                                            {playersList.length > 0 && (
                                                <div>
                                                    <Typography variant="subtitle1">
                                                        Players ({playersList.length}/{MAX_PLAYERS_ALLOWED})
                                                    </Typography>
                                                    <List className={classes.playerList}>
                                                        {playersList.map((player, index) => (
                                                            <ListItem key={index} className={classes.playerItem}>
                                                                <ListItemText
                                                                    primary={`${player.firstName} ${player.lastName}`}
                                                                    secondary={player.email}
                                                                />
                                                                <ListItemSecondaryAction>
                                                                    <IconButton edge="end" aria-label="delete" onClick={() => handleRemovePlayer(index)}>
                                                                        <DeleteIcon />
                                                                    </IconButton>
                                                                </ListItemSecondaryAction>
                                                            </ListItem>
                                                        ))}
                                                    </List>
                                                </div>
                                            )}

                                            {/* Add new player form - only show if we haven't reached max players */}
                                            {canAddMorePlayers() && (
                                                <>
                                                    <Typography variant="subtitle1" style={{ marginTop: '16px' }}>
                                                        {playersList.length > 0 ? 'Add Another Player' : 'Add Player'}
                                                    </Typography>

                                                    <FormControl variant="outlined" className={classes.formControl} fullWidth={true}>
                                                        <TextField
                                                            label="Email"
                                                            variant="outlined"
                                                            color="primary"
                                                            value={currentPlayer.email || ""}
                                                            onChange={(e) => handleCurrentPlayerChange('email', e.target.value)}
                                                            error={currentPlayer.email !== undefined && !currentPlayer.isEmailValid}
                                                            helperText={currentPlayer.email !== undefined && !currentPlayer.isEmailValid ? 'Email must be valid!' : ''}
                                                        />
                                                    </FormControl>

                                                    <FormControl variant="outlined" className={classes.formControl} fullWidth={true}>
                                                        <TextField
                                                            label="First name"
                                                            variant="outlined"
                                                            color="primary"
                                                            value={currentPlayer.firstName || ""}
                                                            onChange={(e) => handleCurrentPlayerChange('firstName', e.target.value)}
                                                            error={currentPlayer.firstName !== undefined && !currentPlayer.isFirstNameValid}
                                                            helperText={currentPlayer.firstName !== undefined && !currentPlayer.isFirstNameValid ? 'First name must be valid!' : ''}
                                                        />
                                                    </FormControl>

                                                    <FormControl variant="outlined" className={classes.formControl} fullWidth={true}>
                                                        <TextField
                                                            label="Last name"
                                                            variant="outlined"
                                                            color="primary"
                                                            value={currentPlayer.lastName || ""}
                                                            onChange={(e) => handleCurrentPlayerChange('lastName', e.target.value)}
                                                            error={currentPlayer.lastName !== undefined && !currentPlayer.isLastNameValid}
                                                            helperText={currentPlayer.lastName !== undefined && !currentPlayer.isLastNameValid ? 'Last name must be valid!' : ''}
                                                        />
                                                    </FormControl>

                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        startIcon={<AddIcon />}
                                                        onClick={handleAddPlayer}
                                                        disabled={!isPlayerFormValid()}
                                                        className={classes.addPlayerButton}
                                                    >
                                                        Add Player
                                                    </Button>
                                                </>
                                            )}
                                        </>
                                    )}
                                </Grid>

                                <FormControl variant="outlined" className={classes.formControl} fullWidth={true}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        style={{minWidth: '400px'}}
                                        fullWidth={true}
                                        disabled={!isFormValid()}
                                        onClick={handleSubmitUpdatePin}
                                    >
                                        Update Booking
                                    </Button>
                                </FormControl>
                            </Grid>
                        </div>
                    </React.Fragment>
                    <Snackbar
                        open={snackBarOpen}
                        autoHideDuration={6000}
                        onClose={handleSnackBarClose}
                    >
                        <Alert onClose={handleSnackBarClose} severity="success">
                            {snackBarMessage}
                        </Alert>
                    </Snackbar>
                </DialogContent>
            </Dialog>
        </MuiThemeProvider>
    );
};