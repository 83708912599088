import {FACILITY_CHANGE, FACILITY_LOADED, FACILITY_UPDATED, FacilityState,} from "../actions/facility.actions";
import jwt from "jwt-decode";
import {LOGOUT} from "../actions/auth.actions.types";

const initialState: FacilityState = {
  selectedFacility: {
    rangeId: "",
    name: "",
    description: "",
    defaultPlayMode: "",
    // timeZone: "",
    // currency: "",
    // phoneNumberFormat: "",
    behavior: "",
    leadTimeMinutes: -1,
    groupOption: "",
  },
  role: "",
  facilities: [],
};

export default (state: FacilityState = initialState, action: any) => {
  if (action?.type === LOGOUT) {
    return initialState;
  }

  if (action?.payload === undefined) {
    return state;
  }

  // TODO DVG FIX ME
  switch (action.type) {
    case FACILITY_CHANGE: {
      const newState = {
        ...state,
        selectedFacility: {
          ...state.selectedFacility,
          name: action.name,
          rangeId: action.payload.rangeId,
          behavior: action.payload.behavior,
          leadTimeMinutes: action.payload.leadTimeMinutes,
          groupOption: action.payload.groupOption,
        },
        role: action.role,
      };

      return newState;
    }
    case FACILITY_UPDATED: {
      return {
        ...state,
        selectedFacility: {
          ...state.selectedFacility,
          rangeId: action.payload.rangeId,
          behavior: action.payload.behavior,
          leadTimeMinutes: action.payload.leadTimeMinutes,
          groupOption: action.payload.groupOption,
        },
      };
    }
    case FACILITY_LOADED: {
      var decodedRangesToken: any = jwt(action.payload.rangesToken);

      // Sort it
      let facilities = decodedRangesToken.ranges;
      if (facilities !== null && facilities !== undefined) {
        facilities = facilities.sort((a: any, b: any): number => {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        });
      }
      return {
        ...state,
        facilities: facilities,
        // selectedFacility: {
        //   name: facilities[0].name,
        // }
        // facilities: [
        //   ...state.facilities.filter((x) => x.rangeId !== action.payload.rangeId),
        //   action.payload,
        // ],
      };
    }

    default:
      return state;
  }
};
