import {
    BAY_STATUS_UPDATED,
    BAYLOADED,
    BAYLOADING,
    BAYSLOADED,
    BAYSLOADING,
    BAYUPDATED,
    BAYUPDATING,
    BOOKING_UPDATED,
} from "./bays.actions.types";
import axios from "axios";
import {BayDbModel, BayStatusType, Pin} from "../../models/bays";
import {Facility} from '../../models/facility';

export const getBay = (facility: string, bay: string) => (
  dispatch: Function
) => {
  dispatch({ type: BAYLOADING });
  axios
    .get(`${process.env.REACT_APP_BASE_URL}/${facility}/Bays/${bay}`)
    .then((res) => {
      dispatch({ type: BAYLOADED, payload: res.data });
    });
};

export const getBays = (facility: string) => (dispatch: Function) => {
  dispatch({ type: BAYSLOADING });

  axios
    // .get(`${process.env.REACT_APP_BASE_URL}/${facility}/Bays`)
    .get(`${process.env.REACT_APP_BOOKINGS_BASE_URL}/ranges/${facility}/bays`)
    .then((res) => {
      var baysReturned = res.data.map((bay: any) => {
          return {
              ...bay,
              bayStatusType: BayStatusType.Available, // TODO DVG FIX ME
              uuid: bay.bayId,
              bayId: bay.bayId,
              pin: bay?.booking?.pin,
              bookingId: bay?.booking?.id,
          } as BayDbModel;
      });

      var sortedBays = baysReturned.sort(
        (a: BayDbModel, b: BayDbModel): number => {
          if (a.bayName < b.bayName) return -1;
          if (a.bayName > b.bayName) return 1;
          return 0;
        }
      );

        // Chaining the second API call to get more data
        return axios.get(`${process.env.REACT_APP_BOOKINGS_BASE_URL}/bay-status/ranges/${facility}/bays/bookings`).then((res2) => {
            const additionalBaysData = res2.data;

            // Update the sortedBays with the additional data using bayId as the key
            return sortedBays.map((bay: BayDbModel) => {
                // Find the matching bay in additionalBaysData based on bayId
                const additionalBayData = additionalBaysData.find((item: any) => item.bayId === bay.bayId);

                if (additionalBayData) {
                    return {
                        ...bay,
                        bayStatusType: additionalBayData.bayStatus,
                        pin: additionalBayData.booking?.pin,
                        bookingId: additionalBayData.booking?.id,
                        groupBooking: additionalBayData.booking?.groupBooking,
                        minutes: additionalBayData.booking?.minutes,
                        isStatic: additionalBayData.booking?.isStatic,
                        startedAt: additionalBayData.booking?.startTime,
                        timeSlotStart: additionalBayData.booking?.timeSlotStart,
                        timeSlotEnd: additionalBayData.booking?.timeSlotEnd
                    };
                }
                return bay; // If no matching bayId is found, return the bay as is
            });
        });
    }).then((facManBays) => {
      dispatch({ type: BAYSLOADED, payload: facManBays });
    });
};

export const assignBayPin = (
  pin: string,
  minutes: number,
  bayId: string,
  facility: string
) => (dispatch: Function) => {
  dispatch({ type: BAYUPDATING });

  axios
    .put(`${process.env.REACT_APP_BASE_URL}/${facility}/Bays/${bayId}/pin`, {
      minutes: minutes,
      pin: pin,
    })
    .then((res) => {
      dispatch({ type: BAYUPDATED, payload: res.data });
    });
};

export const changeBayState = (
  bay: BayDbModel,
  bayStatusType: BayStatusType,
  facility: string
) => (dispatch: Function) => {
  dispatch({ type: BAYUPDATING });

  axios
    .put(
      `${process.env.REACT_APP_BOOKINGS_BASE_URL}/bay-status/ranges/${facility}/bays/${bay.uuid}`,
      { newBayStatus: bayStatusType.toString() }
    )
    .then((_res) => {
        // dispatch({ type: BAYUPDATED, payload: res.data });
    });
};

export const updateBay = (bay: BayDbModel, facility: Facility) => (dispatch: Function) => {
    if (facility !== null && facility.rangeId === bay.rangeId) {
        dispatch({ type: BAYUPDATED, payload: bay });
    }
};

export const bookingUpdated = (booking: Pin, facility: Facility) => (dispatch: Function) => {
    if (facility !== null && facility.rangeId === booking.rangeId) {
        dispatch({ type: BOOKING_UPDATED, payload: booking });
    }
};

export const bayStatusUpdated = (bayStatus: any, facility: Facility) => (dispatch: Function) => {
    if (facility !== null && facility.rangeId === bayStatus.rangeId) {
        dispatch({ type: BAY_STATUS_UPDATED, payload: bayStatus });
    }
}
