import React, { useState, useEffect } from "react";

import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../redux/store";

import { Facility } from "../models/facility";
import CloseIcon from "@material-ui/icons/Close";
import {
  Grid,
  Hidden,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  makeStyles,
  Theme,
  createStyles,
  AppBar,
  Toolbar,
  DialogContent,
  IconButton,
  Dialog,
  Divider,
} from "@material-ui/core";
import { FacilityAccess } from "../redux/actions/auth.actions.types";
import jwt from "jwt-decode";
import { getBays } from "../redux/actions/bays.actions";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexWrap: "wrap",
      width: 400,
      margin: `${theme.spacing(0)} auto`,
    },
    loginBtn: {
      marginTop: theme.spacing(2),
      flexGrow: 1,
    },
    logo: {
      display: "block",
      marginLeft: "auto",
      marginRight: "auto",
      marginBottom: "20px",
    },
    header: {
      textAlign: "center",
      background: "#212121",
      color: "#fff",
    },
    card: {
      marginTop: theme.spacing(10),
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    appBar: {
      position: "relative",
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
        align: "left",
        font: "normal normal 600 26px/32px Montserrat",
        color: "#041A27",
        opacity: 1,
        display: "flex",
        alignItems: "center",
    },
    time: {
        marginLeft: theme.spacing(2),
        align: "left",
        font: "normal normal normal 14px/18px Montserrat",
        spacing: "0px",
        color: "#041A27",
        opacity: 1,
    },
    connectionIndicator: {
      width: 12,
      height: 12,
      borderRadius: '50%',
      marginLeft: 10,
      animation: '$flash 1s infinite alternate',
    },
    connectedIndicator: {
      backgroundColor: '#4CAF50',
    },
    disconnectedIndicator: {
      backgroundColor: '#F44336',
    },
    '@keyframes flash': {
      from: { opacity: 1 },
      to: { opacity: 0.5 },
    },
  })
);

export const FacilityInfo: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [date, setDate] = React.useState<Date>(new Date(Date.now()));
  const selectedFacility = useSelector<RootState, Facility>(
    (selector) => selector.facility.selectedFacility
  );
  
  const isConnected = useSelector<RootState, boolean>(
    (selector) => selector.websocket?.isConnected || false
  );

  const token = useSelector<RootState, string>(
    (selector) => selector.authToken.token
  );

    useEffect(() => {
        const timer = setTimeout(() => setDate(new Date(Date.now())), 1000);
        return () => clearTimeout(timer);
    }, [date]);

  return (
    <React.Fragment>
      <Grid xs={6} item>
        <Typography className={classes.title}>
          {selectedFacility.name}
          <div 
            className={`${classes.connectionIndicator} ${isConnected ? classes.connectedIndicator : classes.disconnectedIndicator}`}
            title={isConnected ? "WebSocket Connected" : "WebSocket Disconnected"}
          />
        </Typography>
        <div className={classes.time}>{date.toLocaleTimeString()}</div>
      </Grid>
    </React.Fragment>
  );
};

export default FacilityInfo;
