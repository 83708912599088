export const BEGIN_LOGIN = "BEGIN_LOGIN";
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const EXCEPTION = "EXCEPTION";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const AUTH_ERROR = "AUTH_ERROR";
export const REFRESH = "REFRESH";

export interface AuthState {
  isAuthenticated: boolean;
  authError: boolean;
  token: string;
  rangesToken: string;
  userName: string;
  facilityAccess: FacilityAccess[];
  isLoading: boolean;
}

export interface FacilityAccess {
  id: string;
  name: string;
}
