import React, { useEffect } from "react";
import {
    Dialog,
    DialogContent,
    makeStyles,
    Theme,
    createStyles,
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    Button,
    Snackbar,
    MuiThemeProvider,
    useTheme,
    Paper,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Pin } from "../../models/bays";
import axios from "axios";
import { Alert } from "@material-ui/lab";
import DeleteIcon from "@material-ui/icons/Delete";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { Facility } from "../../models/facility";

interface AvailableStaticPinProps {
    show: boolean;
    handleClose: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        appBar: {
            position: "relative",
        },
        title: {
            marginLeft: theme.spacing(1),
            flex: 1,
            textAlign: "left",
            font: "normal normal normal 36px/44px Montserrat",
            letterSpacing: "0px",
            color: "#041A27",
            opacity: 1
        },
        noPins: {
            marginLeft: theme.spacing(1),
            flex: 1,
            textAlign: "left",
            font: "normal normal normal 12px/24px Montserrat",
            letterSpacing: "0px",
            color: "#041A27",
            opacity: 1
        },
        root: {
            background: "white",
            "& > *": {
                margin: theme.spacing(1),
            },
        },
        dialog: {
            borderRadius: "15px",
        },
        rootStyle: {
            borderRadius: 12,
        },
        notime: {
            color: "red",
        },
        time: {
            color: "primary",
        },
        formControl: {
            margin: theme.spacing(1),
            minWidth: 150,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
        cellHeading: {
            textAlign: "center",
            font: "normal normal bold 16px/19px Montserrat",
            letterSpacing: "0px",
            color: "#041A27",
            textTransform: "uppercase",
            opacity: 1
        },
        cellEntry: {
            textAlign: "center",
            font: "normal normal normal 16px/19px Montserrat",
            letterSpacing: "0px",
            color: "#041A27",
            opacity: "1",
        }
    })
);

export const AvailableStaticPins: React.FC<AvailableStaticPinProps> = ({
                                                                           show,
                                                                           handleClose,
                                                                       }) => {
    const classes = useStyles();
    const [pins, setPins] = React.useState<Pin[]>([]);
    const [snackBarOpen, setSnackBarOpen] = React.useState(false);
    const [snackBarMessage, setSnackBarMessage] = React.useState("");

    const theme = useTheme();
    const handleSnackBarClose = (
        event?: React.SyntheticEvent,
        reason?: string
    ) => {
        if (reason === "clickaway") {
            return;
        }

        setSnackBarOpen(false);
    };

    const selectedFacility = useSelector<RootState, Facility>(
        (selector) => selector.facility.selectedFacility
    );

    const getAvailableStaticPins = () => {
        if (selectedFacility !== null && selectedFacility.rangeId !== "") {
            // Clear the Pins
            setPins([]);

            axios
                .get(`${process.env.REACT_APP_BOOKINGS_BASE_URL}/ranges/${selectedFacility.rangeId}/bookings/static`)
                .then((res) => {
                    let pinsReturned: Pin[] = res.data
                        .sort(function(a: Pin, b: Pin) {
                            if (a.playMode === b.playMode) {
                                if (a.minutes === b.minutes) {
                                    return a.id > b.id ? 1 : -1;
                                } else {
                                    return a.minutes > b.minutes ? 1 : -1;
                                }
                            } else {
                                if (a.playMode === "SP") {
                                    return -1;
                                } else {
                                    return 1;
                                }
                            }
                        });
                    setPins(pinsReturned);
                });
        }
        return Promise.all([]);
    };

    const refreshPin = (id: string, pin: string) => {
        axios
            .put(
                `${process.env.REACT_APP_BASE_URL}/${selectedFacility.rangeId}/Pins/${pin}/refresh/${id}`,
                {}
            )
            .then((res) => {
                getAvailableStaticPins();
            });
    };

    const deleteBooking = (id: string) => {
        // TODO Do we even need Force? I think to, from admin portal

        axios
            .delete(`${process.env.REACT_APP_BOOKINGS_BASE_URL}/ranges/${selectedFacility.rangeId}/bookings/${id}?force=true`)
            .then((res) => {
                getAvailableStaticPins();
            })
            .catch(reason => console.log(reason));
    };

    useEffect(() => {
    }, [pins]);

    useEffect(() => {
        if (show) getAvailableStaticPins();
    }, [show]);

    return (
        <MuiThemeProvider theme={theme}>
            <Dialog
                fullWidth={true}
                maxWidth={'md'}
                open={show}
                onClose={handleClose}
                classes={{
                    paper: classes.rootStyle,
                }}
                className={classes.dialog}
                aria-labelledby="form-dialog-title"
            >
                <Toolbar>
                    <Typography variant="h6" className={classes.title}>
                        Static PINs
                    </Typography>
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon/>
                    </IconButton>
                </Toolbar>
                <DialogContent>
                    <React.Fragment>
                        {pins.length === 0 && (
                            <Typography variant="subtitle1" className={classes.noPins}>
                                ...
                            </Typography>
                        )}
                        {pins.length > 0 && (
                            <TableContainer>
                                <Table size="small" aria-label="a dense table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className={classes.cellHeading}>PIN</TableCell>
                                            <TableCell className={classes.cellHeading}>Minutes</TableCell>
                                            <TableCell className={classes.cellHeading}>Mode</TableCell>
                                            <TableCell className={classes.cellHeading}>Description</TableCell>
                                            <TableCell className={classes.cellHeading}>Actions</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {pins.map((row, index) => (
                                            <TableRow
                                                style={index % 2 ? {background: "#F3F3F3"} : {background: "white"}}
                                                key={row.id}>
                                                <TableCell className={classes.cellEntry}>{row.pin}</TableCell>
                                                <TableCell className={classes.cellEntry}>{row.minutes}</TableCell>
                                                <TableCell className={classes.cellEntry}>
                                                    {
                                                        row.playMode === "SP" ? 'Inrange' : (row.playMode === "MP" ? 'Inrange+' : row.playMode)
                                                    }
                                                </TableCell>
                                                <TableCell className={classes.cellEntry}>{row.description}</TableCell>
                                                <TableCell className={classes.cellEntry}>
                                                    <div>
                                                        <Button
                                                            variant="text"
                                                            onClick={() => deleteBooking(row.id)}
                                                        >
                                                            <DeleteIcon />
                                                        </Button>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}
                    </React.Fragment>
                    <Snackbar
                        open={snackBarOpen}
                        autoHideDuration={6000}
                        onClose={handleSnackBarClose}
                    >
                        <Alert onClose={handleSnackBarClose} severity="success">
                            {snackBarMessage}
                        </Alert>
                    </Snackbar>
                </DialogContent>
            </Dialog>
        </MuiThemeProvider>
    );
};
