import { LOGOUT } from "../actions/auth.actions.types";

// Action types
export const WEBSOCKET_CONNECTED = "WEBSOCKET_CONNECTED";
export const WEBSOCKET_DISCONNECTED = "WEBSOCKET_DISCONNECTED";
export const WEBSOCKET_RECONNECTING = "WEBSOCKET_RECONNECTING";

// Interface for the WebSocket state
export interface WebSocketState {
  isConnected: boolean;
  isReconnecting: boolean;
}

// Initial state
const initialState: WebSocketState = {
  isConnected: false,
  isReconnecting: false,
};

// Reducer
export default (state: WebSocketState = initialState, action: any) => {
  if (action?.type === LOGOUT) {
    return initialState;
  }

  switch (action.type) {
    case WEBSOCKET_CONNECTED:
      return {
        ...state,
        isConnected: true,
        isReconnecting: false,
      };
    case WEBSOCKET_DISCONNECTED:
      return {
        ...state,
        isConnected: false,
        isReconnecting: false,
      };
    case WEBSOCKET_RECONNECTING:
      return {
        ...state,
        isConnected: false,
        isReconnecting: true,
      };
    default:
      return state;
  }
};
